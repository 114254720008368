import React from 'react';
import { SystemProps, forwardRefWithAs } from '@oms/ui-utils';
import * as S from './styles';

type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type HeadingVariant =
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6';

export interface HeadingProps extends SystemProps {
  as?: HeadingLevel;
  variant?: HeadingVariant;
}

export const Heading = forwardRefWithAs<HeadingProps, 'h1'>(function Heading(
  props,
  ref
) {
  return <S.Heading ref={ref} {...props} />;
});
