import Immutable from 'immutable';
import debounce from 'lodash.debounce';

const defaultOptions = {
  debounceTime: 400,
  debounceMaxWait: 800,
};

const initialState = {
  items: {},
  initialized: false,
  totalSize: 0,
};

type MergeData = {
  key: string;
  values: any;
  id: string;
  type: string;
};

type MetaFrame = {
  values: {
    initial_data_sent?: boolean;
    size?: number;
  };
  id: string;
  type: 'meta';
};

type DeleteData = {
  key: string;
  id: string;
  type: 'delete';
  values?: any;
};

interface EngineState {
  items: Immutable.Map<unknown, unknown>;
  initialized: boolean;
  totalSize: number;
}

export interface ImmutableEngineState extends Immutable.Map<string, any> {
  toJS(): EngineState;
  get<K extends keyof EngineState>(key: K): EngineState[K];
  get<K extends keyof EngineState>(key: K): EngineState[K];
}

/**
 * Handles events from a Jaws instance and dispatches updates on an onUpdate
 * callback. Refactored into its own class for optimal testability.
 */
export default class JawsEngine {
  constructor(options = defaultOptions) {
    // Will trigger after debounceTime ms of no subsequent calls happening,
    // waiting max maxWait ms for this to happen
    this.dispatchState = debounce(this.dispatchState, options.debounceTime, {
      maxWait: options.debounceMaxWait,
      leading: true,
      // Also trigger trailing invocation if triggered during timeout
      trailing: true,
    });
  }

  updateFunction: Function | null = null;
  totalSizeTimeout: ReturnType<typeof setTimeout> | null = null;

  /**
   * This component's state.
   *
   * It is never flushed, is kept in sync with the jaws data
   * and periodically synced to listeners using a debounce function.
   *
   * This is more performant, as the merging to redux is Immutable
   * based, and verey quick, and the debounce function ensures
   * that components do not receive new props too often, resulting
   * in fewer renders.
   */
  state: ImmutableEngineState = Immutable.fromJS(initialState);

  /** Public API */
  onUpdate = (callback: (state: ImmutableEngineState) => void) => {
    // Store a reference to a callback function
    this.updateFunction = callback;
  };

  /** Public API */
  reset = () => {
    this.state = Immutable.fromJS(initialState);
    this.dispatchStateImmediately();
  };

  /** Gets debounced in constructor */
  dispatchState = () => {
    this.dispatchStateImmediately();
  };

  dispatchStateImmediately = (mergeState?: Partial<EngineState>) => {
    if (typeof this.updateFunction !== 'function') {
      throw new Error(
        'JawsEngine not properly set up. Update function missing',
      );
    }

    if (mergeState) {
      this.state = this.state.merge(mergeState);
    }
    this.updateFunction(this.state);
  };

  mergeIntoState = (data: MergeData) => {
    this.state = this.state.mergeIn(['items', data.key], data.values);
  };

  /** Create an item in state */
  handleNewEvent = (data: MergeData) => {
    this.mergeIntoState(data);

    // Wait for initial_data_sent before flushing
    if (!this.state.get('initialized')) return;

    this.dispatchState();
  };

  /** Update an item in state */
  handleUpdateEvent = (data: MergeData) => {
    this.mergeIntoState(data);
    this.dispatchState();
  };

  /** Delete an item from state */
  handleDeleteEvent = (data: DeleteData) => {
    this.state = this.state.deleteIn(['items', data.key]);
    this.dispatchState();
  };

  /** Reset state */
  handleChannelToken = () => {
    this.reset();
  };

  handleMetaEvent = (data: MetaFrame) => {
    if ('initial_data_sent' in data.values) {
      this.dispatchStateImmediately({ initialized: true });
    }

    if ('size' in data.values) {
      // TODO why is this in a timeout?
      if (typeof this.totalSizeTimeout === 'number') {
        clearTimeout(this.totalSizeTimeout);
      }

      this.totalSizeTimeout = setTimeout(() => {
        this.dispatchStateImmediately({ totalSize: data.values.size });
      }, 10);
    }
  };
}
