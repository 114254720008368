import React from 'react';
import {
  forwardRefWithAs,
  splitProps,
  systemProps,
  SystemProps,
} from '@oms/ui-utils';
import { Text } from '@oms/ui-text';
import * as S from './styles';

export interface SwitchProps extends SystemProps {
  name: string;
  value?: string | number;
  /** Switch state indicator text */
  stateLabel?: {
    on: string;
    off: string;
  };
}

export const Switch = forwardRefWithAs<SwitchProps, 'input'>(function Switch(
  { name, value, checked, stateLabel, as: _as, ...props },
  ref,
) {
  const [system, rest] = splitProps(props, systemProps as any);
  const component = (
    <S.Container {...(stateLabel ? {} : system)}>
      <S.Input
        {...rest}
        ref={ref}
        name={name}
        value={value}
        type="checkbox"
        role="switch"
        checked={checked}
        aria-checked={checked}
      />
      <S.Indicator role="presentation" data-indicator="" />
    </S.Container>
  );

  if (stateLabel) {
    return (
      <S.Container {...system}>
        {component}
        <Text data-state-label as="div" size="md" ml={3}>
          {checked ? stateLabel.on : stateLabel.off}
        </Text>
      </S.Container>
    );
  }
  return component;
});

Switch.displayName = 'Switch';
