import React from 'react';
import { Icon, IconProps } from './Icon';
import { light } from './icons';
import { mapProps, Colors } from '@oms/ui-utils';

const getProps = mapProps({
  info: { icon: light.faInfoCircle, color: 'information' as Colors },
  success: {
    icon: light.faCheckCircle,
    color: 'success' as Colors,
  },
  error: { icon: light.faExclamationCircle, color: 'error' as Colors },
  warning: {
    icon: light.faExclamationTriangle,
    color: 'warning' as Colors,
  },
  question: { icon: light.faQuestionCircle, color: 'question' as Colors },
});

export type StatusIntentions =
  | 'info'
  | 'success'
  | 'error'
  | 'warning'
  | 'question';

export type StatusIconProps = Omit<IconProps, 'icon'> & {
  status?: StatusIntentions;
};

export function StatusIcon({ status = 'info', ...props }: StatusIconProps) {
  const { icon, color } = getProps(status);
  return <Icon color={color} {...(props as IconProps)} icon={icon} />;
}
