import React, { InputHTMLAttributes } from 'react';
import { forwardRefWithAs, SpaceProps, noop } from '@oms/ui-utils';
import { TextInputWrapper } from './TextInputWrapper';
import * as S from './styles';

type InputTypes =
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'text'
  | 'month'
  | 'number'
  | 'password'
  | 'tel'
  | 'time'
  | 'url'
  | 'week';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

export interface TextInputProps extends SpaceProps, InputProps {
  type?: InputTypes;
  /** @deprecated */
  leftAddon?: React.ReactNode;
  /** @deprecated */
  rightAddon?: React.ReactNode;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
}

export const TextInput = forwardRefWithAs<TextInputProps, 'input'>(
  function TextInput(
    {
      // HTML input attributes
      id,
      as,
      name,
      type = 'text',
      autoComplete,
      autoFocus,
      autoCorrect,
      pattern,
      placeholder,
      required,
      step,
      min,
      max,
      value,
      list,
      minLength,
      readOnly,
      tabIndex,
      title,
      lang,
      inputMode,
      // React event callbacks
      onChange = noop,
      onFocus = noop,
      onBlur = noop,
      // Aria roles
      role,
      'aria-required': ariaRequired,
      'aria-describedby': ariaDescribedBy,
      'aria-labelledby': ariaLabelledBy,
      'aria-invalid': ariaInvalid,
      'aria-errormessage': ariaErrorMessage,
      'aria-haspopup': ariaHasPopup,
      'aria-owns': ariaOwns,
      'aria-expanded': ariaExpanded,
      'aria-controls': ariaControls,
      'aria-autocomplete': ariaAutoComplete,
      'aria-disabled': ariaDisabled,
      // Padding
      p,
      pt,
      pr,
      pb,
      pl,
      px,
      py,
      disabled,
      // Wrapper props
      leftAddon,
      leftElement = leftAddon,
      rightAddon,
      rightElement = rightAddon,
      ...props
    },
    ref,
  ) {
    return (
      <TextInputWrapper
        data-state={
          (disabled && 'disabled') || (ariaInvalid && 'error') || null
        }
        role={role}
        aria-haspopup={ariaHasPopup}
        aria-owns={ariaOwns}
        aria-expanded={ariaExpanded}
        {...props}
      >
        {leftElement && <S.AddonElement>{leftElement}</S.AddonElement>}
        <S.Input
          as={as}
          disabled={disabled}
          ref={ref}
          id={id}
          name={name}
          type={type}
          lang={lang}
          inputMode={inputMode}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          autoCorrect={autoCorrect}
          pattern={pattern}
          placeholder={placeholder}
          required={required}
          step={step}
          min={min}
          max={max}
          value={value}
          list={list}
          minLength={minLength}
          readOnly={readOnly}
          title={title}
          tabIndex={tabIndex}
          aria-required={required || ariaRequired}
          aria-describedby={ariaDescribedBy}
          aria-labelledby={ariaLabelledBy}
          aria-invalid={ariaInvalid}
          aria-errormessage={ariaErrorMessage}
          aria-controls={ariaControls}
          aria-autocomplete={ariaAutoComplete}
          aria-disabled={ariaDisabled}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          p={p}
          pt={pt}
          pr={pr}
          pb={pb}
          pl={pl}
          px={px}
          py={py}
        />
        {rightElement && <S.AddonElement>{rightElement}</S.AddonElement>}
      </TextInputWrapper>
    );
  },
);

TextInput.displayName = 'TextInput';
