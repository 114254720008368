import React from 'react';
import { forwardRefWithAs, SystemProps } from '@oms/ui-utils';
import * as S from './styles';

const getInitials = (name: string) => {
  const nameAsArray = name.split(' ');
  if (nameAsArray.length > 1) {
    // Gerrit Witteveen => GW
    return nameAsArray.map((partial) => partial?.charAt(0)).join('');
  }
  // Aksjesparekonto => AKS
  return name.substring(0, 3);
};

export interface AvatarProps extends Omit<SystemProps, 'size'> {
  size?: 'sm' | 'md' | 'lg';
  /** Image source */
  src?: string;
  srcSet?: string;
  name: string;
  alt?: string;
}

export const Avatar = forwardRefWithAs<AvatarProps, 'div'>(function Avatar(
  { src, name, size, alt, as, ...props },
  ref,
) {
  // https://web.dev/native-lazy-loading/
  const avatarProps = src
    ? {
        as: 'img',
        src,
        alt: alt || name,
        async: true,
        decoding: 'async',
        loading: 'lazy',
        importance: 'low',
      }
    : { as: 'div', title: name };

  return (
    <S.Mask
      ref={ref}
      data-avatar=""
      avatarSize={size}
      {...props}
      as={as}
      {...((as as string) === 'button' ? { type: 'button' } : {})}
    >
      <S.Avatar {...avatarProps}>{src ? null : getInitials(name)}</S.Avatar>
    </S.Mask>
  );
});

export interface AvatarGroupProps {
  children: React.ReactNode[];
  limit?: number;
}

export function AvatarGroup({ children, limit = 99 }: AvatarGroupProps) {
  const slice = children?.slice(0, limit);
  const remainder = children?.length < limit ? 0 : children?.length - limit;

  return (
    <S.AvatarGroup>
      {slice}
      {remainder > 0 && (
        <S.Remainder aria-label={`${remainder} more avatars in this group`}>
          +{remainder}
        </S.Remainder>
      )}
    </S.AvatarGroup>
  );
}
