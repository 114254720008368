import React from 'react';
import { forwardRefWithAs } from '@oms/ui-utils';
import { ErrorBoundary } from '@oms/ui-error-boundary';
import { LinkProps, UseFieldReturnType, ContextTypes } from './types';

const Link = forwardRefWithAs<LinkProps, 'a'>(({ to, ...props }, ref) => (
  <a ref={ref} href={to} {...props} />
));

const useField = (name: string): UseFieldReturnType => ({
  meta: { touched: false, error: undefined },
  input: {
    name,
    onChange: (...args: any) => void args,
    onFocus: (...args: any) => void args,
    onBlur: (...args: any) => void args,
  },
});

export const defaultContextValue: ContextTypes = {
  Link,
  useField,
  ErrorBoundary,
  // Suggest/Search
};
