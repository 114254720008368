import * as React from 'react';
import {
  system,
  createSystemComponent as csc,
  StyleFunc,
} from '@xstyled/system';

const FINAL_FORM_META_PROPS = Object.freeze([
  'active',
  'data',
  'dirty',
  'dirtySinceLastSubmit',
  'error',
  'initial',
  'invalid',
  'modified',
  'pristine',
  'submitError',
  'submitFailed',
  'submitSucceeded',
  'submitting',
  'touched',
  'valid',
  'validating',
  'visited',
]);

const FINAL_FORM_FIELD_PROPS = Object.freeze([
  'afterSubmit',
  'allowNull',
  'beforeSubmit',
  'component',
  'format',
  'formatOnBlur',
  'initialValue',
  'isEqual',
  'onCreate',
  'parse',
  'validate',
  'validateFields',
]);

const CUSTOM_FIELD_PROPS = Object.freeze([
  'focused',
  'icon',
  'isClearable',
  'isMultiple',
  'isSearchable',
  'renderItem',
  'labelId',
  'messageId',
  'errorId',
]);

const CUSTOM_LAYOUT_PROPS = Object.freeze([
  'gap',
  'orientation',
  'center',
  'alignment',
  'distribution',
  'content',
]);

const s = (system as unknown) as { meta: { props: [] } };

export function createSystemComponent(
  Component: React.ReactNode,
  omitProps: any[] = []
) {
  const unallowedProps = ({
    meta: {
      props: [
        'isPending',
        'connected',
        ...omitProps,
        ...CUSTOM_LAYOUT_PROPS,
        ...CUSTOM_FIELD_PROPS,
        ...FINAL_FORM_FIELD_PROPS,
        ...FINAL_FORM_META_PROPS,
        ...s.meta.props,
      ],
    },
  } as unknown) as StyleFunc;
  return csc(React, Component, unallowedProps);
}
