export const colors = {
  primary: '#00685e',
  secondary: '#0071eb',
  tertiary: '#008d7f',
  danger: '#e61437',
  buy: '#00685e',
  sell: '#e61437',

  'surface-1': '#fff',
  'surface-2': '#f2f4f6',
  'surface-3': '#61798F',
  'surface-4': '#41b6e6', //AA #157AA2
  'surface-5': '#ebf6f5',
  'surface-6': '#252631',
  'surface-7': '#f8e71c',
  'surface-8': '#009639',
  'surface-hover': '#f2f4f6',

  border: '#dfdfdf',
  'contrast-border': '#c8d1da',
  'border-focus': '#0effe7',
  'border-error': '#e61437',
  'border-disabled': '#dfdfdf',
  'inverse-border': '#fff',

  text: '#252631',
  'text-light': '#676767', //AA #676767 on #f2f4f6
  'text-label': '#252631',
  'text-placeholder': '#676767',
  'text-message': '#676767',
  'text-disabled': '#676767',
  'text-error': '#e61437',
  'inverse-text': '#fff',

  link: '#006adb', //AAA #0050c7 //AAA #0056b3

  input: '#F8FAFB',
  'input-focus': '#FFF',
  disabled: '#dfdfdf',

  'icon-primary': '#00685e',
  'icon-secondary': '#006adb',
  'icon-hover': '#F8FAFB',
  'icon-focus': '#F8FAFB',
  'icon-active': '#b0dbd7',

  pos: '#008031',
  'text-pos': '#008031',
  neg: '#e61437',
  'text-neg': '#e61437',
  flat: '#f8e71c',
  'flat-text': '#f8e71c',
  'flat-map': '#fff',
  blend: '#fff',
  /** Not a color, but a value used to blend colors */
  blendTreshold: 1,

  backdrop: '#354c6724',
  'backdrop-orphan': '#FFFFFF24',
  black: '#000',
  white: '#fff',
  current: 'currentColor',
  transparent: 'hsla(0,0%,0%,0)',
  highlight: '#006adb',

  success: '#008031',
  warning: '#f1c21b',
  error: '#e61437',
  information: '#006adb',
  question: '#006adb',

  'success-hover': '#008031',
  'warning-hover': '#f1c21b',
  'error-hover': '#e61437',
  'information-hover': '#006adb',
  'questions-hover': '#006adb',

  'inverse-success': '#008031',
  'inverse-warning': '#f1c21b',
  'inverse-error': '#e61437',
  'inverse-information': '#2cc4f3',
  'inverse-question': '#2cc4f3',

  selected: '#006adb',
  'selected-hover': '#006adb',
  'in-range': '#006adb77',

  skeleton: '#f2f4f6',
  'text-skeleton': '#778ca2',
  'skeleton-base': '#dfdfdf',
  'skeleton-highlight': '#d0d0d0',

  'accent-1': '',
  'accent-2': '',
  'accent-3': '',
  'accent-4': '',
  'accent-5': '',

  'chart-1': '',
  'chart-2': '',
  'chart-3': '',
  'chart-4': '',
  'chart-5': '',
  'chart-fill-start-1': '',
  'chart-fill-start-2': '',
  'chart-fill-start-3': '',
  'chart-fill-start-4': '',
  'chart-fill-start-5': '',
  'chart-fill-end-1': '',
  'chart-fill-end-2': '',
  'chart-fill-end-3': '',
  'chart-fill-end-4': '',
  'chart-fill-end-5': '',

  'chart-background': '#011B47',
  'chart-axis': 'white',
  'chart-axis-label': 'white',
  'chart-legend-label': 'white',
  'chart-text': 'white',
  'chart-last-pointer': '#1467e3',

  'scroll-thumb': 'rgba(0, 0, 0, 0.3)',
  'scroll-track': 'rgba(0, 0, 0, 0.1)',
  'scroll-thumb-idle': 'rgba(0, 0, 0, 0.0)',
  'scroll-track-idle': 'rgba(0, 0, 0, 0.0)',
  'scroll-fade-in': 'rgba(255, 255, 255,1)',
  'scroll-fade-out': 'rgba(255, 255, 255,0)',

  modes: {
    dark: {
      primary: '#008d7f',
      secondary: '#41b6e6',
      tertiary: '#008d7f',
      danger: '#fe4d6a',
      buy: '#008d7f',
      sell: '#fe4d6a',

      'surface-1': '#000',
      'surface-2': '#111',
      'surface-3': '#333836',
      'surface-4': '#41b6e6',
      'surface-5': '#ebf6f5',
      'surface-6': '#252631',
      'surface-7': '#f8e71c',
      'surface-8': '#009639',
      'surface-hover': '#222',

      border: '#333',
      'contrast-border': '#c8d1da',
      'border-focus': '#0effe7',
      'border-error': '#fe4d6a',
      'border-disabled': '#333',
      'inverse-border': '#fff',

      text: '#fff',
      'text-label': '#fff',
      'text-placeholder': '#ddd',
      'text-message': '#ddd',
      'text-disabled': '#ddd',
      'text-error': '#fe4d6a',
      'inverse-text': '#fff',

      link: '#41b6e6',

      input: '#111',
      'input-focus': '#000',
      disabled: '#222',

      'icon-primary': '#ebf6f5',
      'icon-secondary': '#41b6e6',
      'icon-hover': '#F8FAFB',
      'icon-focus': '#F8FAFB',
      'icon-active': '#b0dbd7',

      pos: '#75c25c',
      'text-pos': '#75c25c',
      neg: '#fe4d6a',
      'text-neg': '#fe4d6a',
      flat: '#f8e71c',
      'flat-text': '#f8e71c',
      'flat-map': '#fff',
      blend: '#fff',
      /** Not a color, but a value used to blend colors */
      blendTreshold: 1,

      backdrop: '#354c6724',
      black: '#000',
      white: '#fff',
      current: 'currentColor',
      transparent: 'hsla(0,0%,0%,0)',
      highlight: '#41b6e6',

      success: '#75c25c',
      warning: '#f1c21b',
      error: '#fe4d6a',
      information: '#41b6e6',
      question: '#41b6e6',

      'success-hover': '#75c25c',
      'warning-hover': '#f1c21b',
      'error-hover': '#fe4d6a',
      'information-hover': '#41b6e6',
      'questions-hover': '#41b6e6',

      'inverse-success': '#75c25c',
      'inverse-warning': '#f1c21b',
      'inverse-error': '#fe4d6a',
      'inverse-information': '#2cc4f3',
      'inverse-question': '#2cc4f3',

      selected: '#41b6e6',
      'selected-hover': '#41b6e6',
      'in-range': '#2cc4f344',

      skeleton: '#f2f4f6',
      'text-skeleton': '#778ca2',
      'skeleton-base': '#dfdfdf',
      'skeleton-highlight': '#d0d0d0',

      'accent-1': '',
      'accent-2': '',
      'accent-3': '',
      'accent-4': '',
      'accent-5': '',

      'chart-1': '',
      'chart-2': '',
      'chart-3': '',
      'chart-4': '',
      'chart-5': '',
      'chart-fill-start-1': '',
      'chart-fill-start-2': '',
      'chart-fill-start-3': '',
      'chart-fill-start-4': '',
      'chart-fill-start-5': '',
      'chart-fill-end-1': '',
      'chart-fill-end-2': '',
      'chart-fill-end-3': '',
      'chart-fill-end-4': '',
      'chart-fill-end-5': '',

      'scroll-thumb': 'rgba(0, 0, 0, 0.3)',
      'scroll-track': 'rgba(0, 0, 0, 0.1)',
      'scroll-thumb-idle': 'rgba(0, 0, 0, 0.0)',
      'scroll-track-idle': 'rgba(0, 0, 0, 0.0)',
      'scroll-fade-in': 'rgba(255, 255, 255,1)',
      'scroll-fade-out': 'rgba(255, 255, 255,0)',
    },
  },
};

export const shadows = {
  focus: '0 0 0 3px #b0dbd777',
  'focus-error': '0 0 0 3px #e0bbbe77',
  'focus-inset': 'inset 0 0 0 3px rgba(66, 153, 225, 0.6)',
  menu: '0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)',
};

export const fonts = {
  heading: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  body: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  label: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
};
