import React, { useRef, useState, useEffect, ReactNode } from 'react';
import { forwardRefWithAs, CommonProps, useForkRef } from '@oms/ui-utils';
import * as S from './styles';

interface TextInputWrapperProps extends CommonProps {
  children: ReactNode;
  leftAddon?: ReactNode;
  rightAddon?: ReactNode;
  role?: string;
}

export const TextInputWrapper = forwardRefWithAs<TextInputWrapperProps, 'div'>(
  ({ children, ...props }, passedRef) => {
    const innerRef = useRef<HTMLDivElement>(null);
    const registerRef = useForkRef(passedRef, innerRef);
    const [hoverState, setHoverState] = useState<boolean>(false);
    const [focusState, setFocusState] = useState<boolean>(false);

    useEffect(() => {
      const focusHandler = ({ type }: FocusEvent) => {
        if (type === 'focusin') {
          setFocusState(true);
        } else {
          setFocusState(false);
        }
      };

      const hoverHandler = ({ type }: FocusEvent) => {
        if (type === 'mouseover') {
          setHoverState(true);
        } else {
          setHoverState(false);
        }
      };

      const element = innerRef?.current;
      // Focus
      element?.addEventListener('focusin', focusHandler);
      element?.addEventListener('focusout', focusHandler);
      // Hover
      element?.addEventListener('mouseover', hoverHandler);
      element?.addEventListener('mouseout', hoverHandler);

      return () => {
        element?.removeEventListener('focusin', focusHandler);
        element?.removeEventListener('focusout', focusHandler);
        element?.removeEventListener('mouseover', hoverHandler);
        element?.removeEventListener('mouseout', hoverHandler);
      };
    }, []);

    return (
      <S.InputWrapper
        ref={registerRef}
        {...props}
        data-hover={hoverState}
        data-focus={focusState}
      >
        {children}
      </S.InputWrapper>
    );
  }
);
