import React from 'react';
import {
  ErrorBoundary as Boundary,
  FallbackProps,
  ErrorBoundaryProps as BoundaryProps,
} from 'react-error-boundary';
import { Scroll } from '@oms/ui-scroll';
import { warning } from '@oms/ui-utils';
import * as S from './styles';

type Component<Props> = (props: Props) => JSX.Element;
export interface ErrorBoundaryProps {
  fallbackRenderer?: Component<FallbackProps>;
  onError?: BoundaryProps['onError'];
  children: React.ReactNode;
}

export function ErrorBoundary({
  fallbackRenderer = FallbackComponent,
  onError = (error, componentStack) => {
    warning(true, error?.message, componentStack);
  },
  children,
}: ErrorBoundaryProps) {
  return (
    <Boundary onError={onError} FallbackComponent={fallbackRenderer}>
      {children}
    </Boundary>
  );
}

function FallbackComponent({
  componentStack,
  resetErrorBoundary,
}: FallbackProps & {
  message?: string;
  title?: string;
}) {
  const componentName = componentStack
    ?.split('\n')[1]
    .trimStart()
    .split('in ')[1];
  return (
    <>
      <S.Stack>
        <S.Heading>
          Looks like there was a problem in {componentName}.
        </S.Heading>
        <S.Text>You may refresh the component or try again later.</S.Text>
        {process.env.NODE_ENV === 'development' && (
          <S.ComponentStack>
            <Scroll maxHeight="15rem">{componentStack}</Scroll>
          </S.ComponentStack>
        )}
        <S.Button onClick={resetErrorBoundary}>
          <span style={{ display: 'inline-block' }}>
            <Icon />
          </span>
          Refresh
        </S.Button>
      </S.Stack>
    </>
  );
}

function Icon() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="redo"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="0.675rem"
      height="0.675rem"
      style={{ marginRight: '0.75rem' }}
    >
      <path
        fill="currentColor"
        d="M492 8h-10c-6.627 0-12 5.373-12 12v110.625C426.804 57.047 346.761 7.715 255.207 8.001 118.82 8.428 7.787 120.009 8 256.396 8.214 393.181 119.166 504 256 504c63.926 0 122.202-24.187 166.178-63.908 5.113-4.618 5.354-12.561.482-17.433l-7.069-7.069c-4.503-4.503-11.749-4.714-16.482-.454C361.218 449.238 311.065 470 256 470c-117.744 0-214-95.331-214-214 0-117.744 95.331-214 214-214 82.862 0 154.737 47.077 190.289 116H332c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h160c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12z"
      ></path>
    </svg>
  );
}
