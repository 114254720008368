import React from 'react';
import { Icon, IconProps } from './Icon';
import { light } from './icons';
import { mapProps } from '@oms/ui-utils';

const getProps = mapProps({
  pos: {
    rotation: 45 as const,
    color: 'pos' as const,
  },
  flat: {
    rotation: 90 as const,
    color: 'flat' as const,
  },
  neg: {
    rotation: 135 as const,
    color: 'neg' as const,
  },
});

type Props = {
  trend: 'pos' | 'flat' | 'neg';
} & Omit<IconProps, 'icon'>;

/**
 * A component which displays a simple colorized arrow
 * indicating change. Pass it a trend prop of `"pos", "flat", or "neg"`.
 */
export const TrendArrowIcon = ({ trend, ...props }: Props) => {
  const { color, rotation } = getProps(trend);
  return (
    <Icon
      color={color}
      rotation={rotation}
      icon={light.faLongArrowUp}
      {...props}
    />
  );
};

export default TrendArrowIcon;
