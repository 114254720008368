import React from 'react';
import {
  formatNumber,
  formatNumberWithUnit,
  formatInteger,
  formatDate,
} from '@oms/utils';
import { usePrevious, useConstant } from '@oms/ui-utils';
import { useLink } from '@oms/ui-components-context';
// import { useLanguage } from '@oms/ui-language-context';
import { DataType, TdProps } from './types';
import { useAttributes } from './utils';
import { TdBox, Link, Ticker, Name, SymbolWrapper } from './styles';

const formatters: Partial<Record<DataType, any>> = {
  percent: formatNumber,
  number: formatNumber,
  numberWithUnit: formatNumberWithUnit,
  integer: formatInteger,
  dateTime: formatDate,
};
const unformatted = (item: any) => item;

export const formatter = (key?: string): ((...args: any) => any) => {
  return (formatters as any)[key as string] || unformatted;
};

const LinkWrapper = ({ to, children, title }: any) => {
  const LinkImpl = useLink();
  return to ? (
    <Link as={LinkImpl} to={to} title={title}>
      {children}
    </Link>
  ) : (
    children
  );
};

export const TimeWrapper = ({ children, type }: any) => {
  return type === 'dateTime' ? (
    <time aria-readonly={true} /*dateTime={new Date(date)?.toISOString()}*/>
      {children}
    </time>
  ) : (
    children
  );
};

export const TableCellLInk = LinkWrapper;

export function Td<T extends DataType>({
  innerRef: passedRef,
  __whyDidYouUpdate = false,
  type,
  children,
  ...props
}: TdProps<T>) {
  const {
    // Adds a link wrapper
    linkTo,
    // Needed for date formatting, adds a time-element wrapper
    locale = 'nb',
    // Needed for date formatting, adds a time-element wrapper
    formatString = ['HH:mm', 'DD. MMM', 'DD. MMM YYYY'],
    // Needed for number formatting
    fractionDigits = type === 'numberWithUnit' ? 2 : undefined,
    // Needed for number formatting
    formatOptions = {},
    // style
    prefix,
    // style
    suffix = type === 'percent' ? '%' : undefined,
    // style
    truncate,
    // style
    trend,
    // style
    flash,
    // calculations
    timestamp: _timestamp,
    // content
    ticker,
    // content
    name,
    ...systemProps
  } = props as any;

  const previousValue = usePrevious(children);

  const attributes = useAttributes({
    value: children,
    previousValue,
    prefix,
    suffix,
    truncate,
    trend,
    flash,
    type,
  });

  const args = useConstant(() =>
    type === 'dateTime'
      ? [formatString, locale]
      : [fractionDigits ?? formatOptions],
  );

  const textAlignment = useConstant(() =>
    type && ['number', 'numberWithUnit', 'percent', 'integer'].includes(type)
      ? 'right'
      : undefined,
  );

  const formatChildren = useConstant(() => formatter(type));

  return (
    <TdBox
      ref={passedRef}
      textAlign={textAlignment}
      {...systemProps}
      {...attributes}
    >
      <LinkWrapper to={linkTo}>
        <TimeWrapper date={children} type={type}>
          {type === 'symbol' ? (
            <SymbolWrapper>
              <Ticker>{ticker}</Ticker>
              <Name>{name}</Name>
            </SymbolWrapper>
          ) : children === '-' ? (
            '-'
          ) : (
            //formatter(type)(children, ...args) || '\u00a0'
            formatChildren(children, ...args) || '\u00a0'
          )}
        </TimeWrapper>
      </LinkWrapper>
    </TdBox>
  );
}

export const MemoizedTd = React.memo(Td);

/*
  useEffect(() => {
    const td = (innerRef.current as unknown) as HTMLTableCellElement;
    let timeout: NodeJS.Timeout;
    if (shouldApplyChangeClass(previousValue, children, type)) {
      td.classList.add('data-has-changed');
      timeout = setTimeout(() => {
        td.classList.remove('data-has-changed');
      }, 500);
    }
    return () => {
      clearTimeout(timeout);
      td.classList.remove('data-has-changed');
    };
  }, [previousValue, children, type]);
'*/
