import urlTemplate from 'url-template';

/**
 * Places a parameter (usually an ITEM_SECTOR) into a template at the
 * position of a given placeholder.
 *
 * Uses an RFC6570 compatible library called url-template to parse the variables
 * and interpolate into the template.
 *
 * Recurses over the template if it is an object, so that it can support a
 * history location object.
 *
 * @param {string} template - The template to use for formatting
 * @param {object} variables - The variables to expand into the template
 * @return {string} The formatted template
 */
export default function formatLink<
  Link extends string | { [key: string]: any }
>(template: Link, variables?: object): Link {
  if (!variables) {
    return template;
  }

  if (typeof template === 'string') {
    return urlTemplate.parse(template).expand(variables) as Link;
  } else {
    // Recurse over object's own properties
    return Object.entries(template).reduce(
      (reduction, [key, value]) => ({
        ...reduction,
        [key]: formatLink(value, variables),
      }),
      {},
    ) as Link;
  }
}
