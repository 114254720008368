import debounce from 'lodash.debounce';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';
import {
  ResizeObserver as ResizeObserverPolyfill,
  ResizeObserverEntry as ResizeObserverEntryPolyfill,
} from '@juggle/resize-observer';
import convertLength from 'convert-css-length';

type HTMLElementRef<T extends HTMLElement = HTMLElement> =
  | React.RefObject<T>
  | React.MutableRefObject<T | null>
  | React.RefObject<T | null>
  | T
  | null;

const TWO_SHOTS = 32.66666666666667;

const ResizeObserverConstructor = ResizeObserverPolyfill;
/*
  typeof window !== undefined && window.hasOwnProperty('ResizeObserver')
    ? window.ResizeObserver
    : ResizeObserverPolyfill;
*/
type PartialContentRect = {
  width: number;
  height: number;
  top: number;
  left: number;
};

type Entry = {
  contentRect: PartialContentRect;
};

type IgnoreDimensions = keyof PartialContentRect | (keyof PartialContentRect)[];

interface ObservedElement extends Element {
  onResizeObservation?: (entry: ResizeObserverEntry) => void;
}

interface ResizeObserverEntry extends ResizeObserverEntryPolyfill {
  readonly target: ObservedElement;
}

export const ElementQueryContext = createContext<InstanceType<
  typeof ResizeObserverConstructor
> | null>(null);

export const useElementQueryContext = () => {
  const context = useContext(ElementQueryContext);
  if (!context)
    throw new Error(
      'ResizeObserver hooks can only be used in descendants of ElementQueryProvider',
    );
  return context;
};

export const ElementQueryProvider = ({ children }: any) => {
  const animationFrameID = useRef(0);

  const instance = useMemo(() => {
    const handleResizeObserverEntry = (
      resizeObserverEntry: ResizeObserverEntry,
    ) => {
      const { onResizeObservation } = resizeObserverEntry.target;
      onResizeObservation?.(resizeObserverEntry);
    };

    return new ResizeObserverConstructor((entries) => {
      for (let entry of entries) {
        animationFrameID.current = window.requestAnimationFrame(() => {
          handleResizeObserverEntry(entry as any);
        });
      }
    });
  }, []);

  useEffect(() => {
    const observer = instance;
    return () => {
      window.cancelAnimationFrame(animationFrameID.current);
      observer.disconnect();
    };
  }, [instance]);

  return (
    <ElementQueryContext.Provider value={instance}>
      {children}
    </ElementQueryContext.Provider>
  );
};

//////////////////////////////////////////////////////////////////////////

export const useResizeObserverInternal = (
  ref: HTMLElementRef,
  ignoreDimensions?: IgnoreDimensions,
  debounceWait: number = TWO_SHOTS,
) => {
  const resizeObserver = useElementQueryContext();

  const [state, setState] = useState<PartialContentRect>({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });

  const handleResizeObservation = useMemo(() => {
    const normalized = [ignoreDimensions].flat();

    return debounce(
      (entry: ResizeObserverEntry) => {
        const { left, top, width, height } = entry.contentRect;
        const incoming = { left, top, width, height };
        setState((existing) => {
          const stateKeys = Object.keys(
            existing,
          ) as (keyof PartialContentRect)[];
          const keysWithChanges = stateKeys.filter(
            (key) => existing[key] !== incoming[key],
          );
          const shouldBail = keysWithChanges.every((key) =>
            normalized.includes(key),
          );

          return shouldBail ? existing : incoming;
        });
      },
      debounceWait,
      {
        leading: true,
        maxWait: debounceWait * 10,
      },
    );
  }, [ignoreDimensions, debounceWait]);

  useEffect(() => {
    const node =
      ref && 'current' in ref
        ? (ref.current as ObservedElement)
        : (ref as ObservedElement);

    if (node) {
      node.onResizeObservation = handleResizeObservation;
      resizeObserver?.observe(node);
    }
    return () => {
      handleResizeObservation.cancel();
      if (node) {
        delete node.onResizeObservation;
        resizeObserver?.unobserve(node);
      }
    };
  }, [handleResizeObservation, resizeObserver, ref]);

  return state;
};

//////////////////////////////////////////////////////////////////////////

export function useResizeObserver<T extends HTMLElement>(
  ignoreDimensions: IgnoreDimensions = ['top', 'left'],
  debounceWait: number = TWO_SHOTS,
) {
  const [elementRef, setElementRef] = useState<T | null>(null);

  const contentRect = useResizeObserverInternal(
    elementRef,
    ignoreDimensions,
    debounceWait,
  );
  return [setElementRef, { contentRect }] as const;
}

//////////////////////////////////////////////////////////////////////////

function getDefaultFontSize() {
  if (typeof window === undefined) {
    return '16px';
  } else {
    const style = window
      .getComputedStyle(document.body, null)
      .getPropertyValue('font-size');
    // now you have a proper float for the font size
    // (yes, it can be a float, not just an integer)
    const fontSize = parseFloat(style);
    return fontSize.toString() + 'px';
  }
}

const convert = convertLength(getDefaultFontSize());

export function useMinWidthContainerQuery<T extends string | number>(
  ref: HTMLElementRef,
  minWidths: T[],
  debounceWait: number = TWO_SHOTS,
) {
  const entry = useResizeObserverInternal(
    ref,
    ['height', 'left', 'top'],
    debounceWait,
  );
  const [minWidth, setMinWidth] = useState<T | 0>(0);

  useEffect(() => {
    const incomingMinWidth = minWidths
      .reverse()
      .find((minWidthEntry: string | number) => {
        const size =
          typeof minWidthEntry === 'number'
            ? minWidthEntry
            : Number(convert(minWidthEntry, 'px').replace('px', ''));
        return entry.width > size;
      });

    if (incomingMinWidth) {
      setMinWidth((existing) =>
        existing === incomingMinWidth ? existing : incomingMinWidth,
      );
    } else {
      setMinWidth(0);
    }
  }, [entry, minWidths]);

  return [{ 'data-container-min-width': minWidth }, minWidth, entry] as const;
}

//////////////////////////////////////////////////////////////////////////

interface ObserveElementRenderProps {
  observedElementProps: { ref: React.Ref<any> };
  widthMatch: string;
}

type BreakpointsOptions<T extends number | string> = Record<
  'widths',
  Record<number, T>
>;

interface ObserveElementProps<T extends number | string> {
  breakpoints: BreakpointsOptions<T>;
  render: ({
    observedElementProps,
    widthMatch,
  }: ObserveElementRenderProps) => JSX.Element;
  debounceWait?: number;
}

const ObserveElementContext = React.createContext<PartialContentRect>({
  width: 0,
  height: 0,
  top: 0,
  left: 0,
});

export function ElementObserver({
  breakpoints,
  render,
  debounceWait = TWO_SHOTS,
}: ObserveElementProps<string>) {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const minWidths = Object.keys(breakpoints.widths).map(Number);
  const [, match, entry] = useMinWidthContainerQuery(
    ref,
    minWidths,
    debounceWait,
  );
  const index = minWidths.findIndex((minWidth) => minWidth === match);
  const keys = Object.values(breakpoints.widths);

  return (
    <ObserveElementContext.Provider value={entry}>
      {render({
        observedElementProps: { ref: setRef },
        widthMatch: keys[index] || keys[0],
      })}
    </ObserveElementContext.Provider>
  );
}

export function useResizeObserverEntry() {
  return React.useContext(ObserveElementContext);
}

//////////////////////////////////////////////////////////////////////////

export function useElementQuery<T extends number | string>(
  /** Define your breakpoints from smallest to biggest */
  breakpoints: BreakpointsOptions<T>,
  entry: Entry,
) {
  return React.useMemo(() => {
    const minWidths = Object.keys(breakpoints.widths).map(Number).reverse();
    const index = minWidths.findIndex(
      (minWidth) => entry.contentRect.width > minWidth,
    );
    const keys = Object.values(breakpoints.widths).reverse();
    return [keys[index] || keys[0]] as const;
  }, [breakpoints, entry]);
}
