import React, { useCallback, ReactNode } from 'react';
import {
  forwardRefWithAs,
  splitProps,
  systemProps,
  SystemProps,
} from '@oms/ui-utils';
import { Box } from '@oms/ui-box';
import { Stack } from '@oms/ui-stack';
import * as S from './styles';
import {
  getLabelId,
  getErrorId,
  getMessageId,
  getAriaProps,
  getCheckableId,
  getCheckableLabelId,
  getCheckableMessageId,
  getDisplayName,
} from './utils';

export interface RenderFieldProps extends SystemProps {
  name: string;
  as: any;
  label: string | JSX.Element;
  /** Using the placement option only affects text input like components  */
  message?:
    | string
    | JSX.Element
    | { placement: 'top' | 'bottom'; text: string | JSX.Element };
  error?: string | string[];
  content?: ReactNode;
  disabled?: boolean;
  onChange?: any;
}

/**
 * A component that handles the field layout, label, message and error
 * @namespace
 */
export const RenderField = forwardRefWithAs<RenderFieldProps, 'input'>(
  function Field(
    {
      as: Component,
      name,
      label,
      message,
      error,
      required,
      content,
      onChange,
      disabled,
      ...props
    },
    ref,
  ) {
    const handleChange = useCallback(
      (...e: any) => {
        if (!disabled) {
          onChange(...e);
        }
      },
      [onChange],
    );

    const [system, _componentProps] = splitProps<any, any>(props, systemProps),
      componentProps = { ..._componentProps, onChange: handleChange, disabled },
      labelId = getLabelId(name),
      messageId = getMessageId(name),
      errorId = getErrorId(name),
      checkableId = getCheckableId(name, props.value as any),
      checkableLabelId = getCheckableLabelId(name, props.value as any),
      checkableMessageId = getCheckableMessageId(name, props.value as any),
      displayName = getDisplayName(Component) as string,
      cases = (c: string[]) => c.includes(displayName);

    const getMessagePlacement = (m: typeof message) =>
      m &&
      typeof m === 'object' &&
      'text' in m &&
      'placement' in m &&
      m.placement === 'bottom'
        ? 'bottom'
        : 'top';

    const messageElement = message ? (
      typeof message === 'object' &&
      'text' in message &&
      'placement' in message ? (
        <S.Message id={messageId}>{message?.text}</S.Message>
      ) : (
        <S.Message id={messageId}>{message}</S.Message>
      )
    ) : null;

    switch (true) {
      case cases(['Segment', 'Choice']):
        return (
          <Component
            ref={ref}
            id={checkableId}
            name={name}
            {...system}
            {...componentProps}
            {...getAriaProps({
              error,
              message,
              errorId,
              messageId: checkableMessageId,
            })}
            required={required}
            label={label}
            message={message}
            messageId={checkableMessageId}
            errorId={errorId}
            content={content}
          />
        );
      case cases(['Switch']):
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            {...system}
          >
            <Box display="flex" flexDirection="column" flex={1}>
              <S.Label
                htmlFor={name}
                color={componentProps.disabled ? 'text-disabled' : undefined}
              >
                {label}
              </S.Label>
              {message && <S.Message id={messageId}>{message}</S.Message>}
            </Box>
            <Component
              ref={ref}
              id={name}
              name={name}
              {...componentProps}
              {...getAriaProps({
                error,
                message,
                errorId,
                messageId,
              })}
              required={required}
            />
          </Box>
        );
      case cases(['Checkbox', 'Radio', 'ColorInput']):
        return (
          <Box
            display="flex"
            as="label"
            id={checkableLabelId}
            htmlFor={checkableId}
            flexDirection="row"
            alignItems="center"
            {...system}
          >
            <Component
              ref={ref}
              id={checkableId}
              name={name}
              {...componentProps}
              {...getAriaProps({
                error,
                message,
                errorId,
                messageId: checkableMessageId,
              })}
              required={required}
            />
            <S.Label
              as="div"
              ml={2}
              required={required}
              color={componentProps.disabled ? 'text-disabled' : undefined}
              data-cursor={componentProps.disabled ? 'not-allowed' : 'pointer'}
            >
              {label}
            </S.Label>
          </Box>
        );
      default:
        return (
          <Stack gap={1} {...system}>
            <S.Label
              id={labelId}
              htmlFor={name}
              required={required}
              color={componentProps.disabled ? 'text-disabled' : undefined}
            >
              {label}
            </S.Label>
            {getMessagePlacement(message) === 'top' ? messageElement : null}
            <Component
              ref={ref}
              id={name}
              name={name}
              {...componentProps}
              {...getAriaProps({
                error,
                message,
                errorId,
                messageId,
                disabled,
              })}
              labelId={labelId}
              required={required}
            />
            {getMessagePlacement(message) === 'bottom' ? messageElement : null}
            <S.Error
              id={errorId}
              as={Array.isArray(error) ? 'ul' : 'p'}
              data-hidden={Array.isArray(error) ? error.length === 0 : !error}
            >
              {Array.isArray(error) ? (
                error.map((err) => <li>{err}</li>)
              ) : (
                <>
                  <ErrorIcon />
                  {error}
                </>
              )}
            </S.Error>
          </Stack>
        );
    }
  },
);

export function ErrorIcon() {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="exclamation-circle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      style={{
        width: '0.75rem',
        display: 'inline',
        marginRight: '0.5rem',
        transform: 'translateY(2px)',
      }}
    >
      <path
        fill="currentColor"
        d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
      ></path>
    </svg>
  );
}
