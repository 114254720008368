import React, { HTMLProps } from 'react';
import { css } from '@emotion/core';

const focusButton = css`
  :not(:focus) {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
  }
`;

export const FocusButton = ({
  children,
  className = 'OmsFocusButton',
  type = 'button',
  ...props
}: HTMLProps<HTMLButtonElement>) => (
  <button
    type={type as JSX.IntrinsicElements['button']['type']}
    css={focusButton}
    {...props}
  >
    {children}
  </button>
);

export default FocusButton;
