import React from 'react';
import { motion } from 'framer-motion';
import {
  Disclosure as RDisclosure,
  useDisclosureState as useRDisclosure,
} from 'reakit/Disclosure';
import { ColorProps, As, SystemProps, forwardRefWithAs } from '@oms/ui-utils';
import * as S from './styles';
import {
  DisclosureProps as RDisclosureProps,
  DisclosureContentProps as RDisclosureContentProps,
} from 'reakit/Disclosure';

export const useDisclosure = useRDisclosure;

export interface DisclosureProps
  extends Omit<RDisclosureProps, 'color'>,
    ColorProps {}

export const DisclosureButton = forwardRefWithAs<DisclosureProps, 'button'>(
  function DisclosureButton({ as = 'button', ...props }, ref) {
    return <RDisclosure ref={ref} {...(props as any)} as={as} />;
  },
);

const variants = {
  hidden: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    transitionEnd: {
      visibility: 'hidden',
    },
  },
  visible: {
    visibility: 'visible',
    opacity: 1,
    height: 'auto',
    transitionEnd: {
      overflow: 'visible',
    },
  },
};

export interface DisclosureContentProps
  extends Omit<RDisclosureContentProps, 'color'>,
    SystemProps {
  as?: As;
}

/**
 * The `Disclosure`-components implement the disclosure design pattern  which allows for content to be shown or hidden.
 * @see [disclosure design pattern](https://www.w3.org/TR/wai-aria-practices/examples/disclosure/disclosure-faq.html)
 */
export const DisclosureContent = forwardRefWithAs<
  DisclosureContentProps,
  'div'
>(function DisclosureContent({ as: element = 'div', children, ...props }, ref) {
  return (
    <>
      <S.DisclosureContent {...props} ref={ref}>
        {({ hidden, styles: _styles, ...disclosureProps }: any) => {
          return (
            <motion.div
              {...disclosureProps}
              initial="hidden"
              animate={hidden ? 'hidden' : 'visible'}
              variants={variants}
              aria-hidden={hidden}
              tabIndex={hidden ? -1 : undefined}
            >
              {children}
            </motion.div>
          );
        }}
      </S.DisclosureContent>
    </>
  );
});
