import { defaults } from './defaults';
import { colors, shadows, fonts } from './tokens';
import { components } from './components';
/**
 * Theme config
 * @memberof theming
 */
export const theme = {
  useColorSchemeMediaQuery: false,
  useCustomProperties: true,
  ...defaults,
  fonts: {
    ...defaults.fonts,
    ...fonts,
  },
  shadows: {
    ...defaults.shadows,
    ...shadows,
  },
  colors,
  ...components,
};
