export const faLeaf = {
  prefix: 'far',
  iconName: 'leaf',
  icon: [
    576,
    512,
    [],
    'f06c',
    'M546.2 9.7c-2.9-6.5-8.6-9.7-14.3-9.7-5.3 0-10.7 2.8-14 8.5C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 17.8 2.6 34.9 7.1 51.2C29 403.7 1.8 478.8 1.3 480.2c-4.3 12.5 2.3 26.2 14.8 30.5 14 4.8 26.7-3.8 30.5-14.8.4-1.1 21-57.5 76.3-110.1C160.5 449 231.5 487 308.4 478.9 465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6zM303.4 431.2c-86.1 9.1-130.6-54.5-142.2-76.5 47.4-32.9 112-58.7 198.9-58.7 13.2 0 24-10.8 24-24s-10.8-24-24-24c-91.3 0-161.1 25.5-214 59.4-.9-6.4-2-12.8-2-19.4 0-79.4 64.6-144 144-144h80c57.9 0 111.6-22 152-60.9 5.2 23.2 8 47.5 8 71.2-.1 151-93.9 267.4-224.7 276.9z',
  ],
};

export const faStar = {
  prefix: 'far',
  iconName: 'star',
  icon: [
    576,
    512,
    [],
    'f005',
    'M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z',
  ],
};

export const faStarHalfAlt = {
  prefix: 'far',
  iconName: 'star-half-alt',
  icon: [
    536,
    512,
    [],
    'f5c0',
    'M390.6 439.61l-7.94 5.88-22.39 42.5 38.67 20.28c4.7 2.45 9.68 3.7 14.83 3.7 9.41 0 18.68-4.38 24.74-11.59 4.84-5.75 7.41-12.73 7.41-20.2l-7.91-48.69-47.41 8.12zM278.98 391.6c-6.77-3.54-14.92-4.21-22.18-.4l-113.32 59.44 23.83-138.31-100.8-98.02 139.28-20.28 62.2-125.88 10.02 20.29 43.15-21.22-24.41-49.41C291.29 6.83 280.24 0 267.92 0c-12.46 0-23.19 6.67-28.68 17.81l-65.41 132.38-146.4 21.3c-12.21 1.8-22.11 10.19-25.8 21.73-3.84 11.69-.74 24.28 8.09 32.86l105.9 103-25.05 145.5c-1.61 9.45.86 18.64 6.94 25.83 6.12 7.27 15.34 11.59 24.69 11.59 5.2 0 10.25-1.3 14.86-3.75l130.95-68.68 45.58 23.91 22.39-42.5-56.77-29.78-.23.4zm51.23-197.57l75.95 11.06 6.95-47.5-50.96-7.41-22.77-46.09-43.15 21.22 33.98 68.72zm204.22-.62c-3.76-11.73-13.67-20.12-25.89-21.92l-43.32-6.31-6.95 47.5 3.21 9.42 33.57 34.38L526.38 226c6.22-6.09 9.63-14.14 9.63-22.66l-1.58-9.93zM368.68 312.33l13.01 75.52 47.41-8.12-8.72-50.64 36.86-35.86-33.57-34.38-54.99 53.48z',
  ],
};

export const faFunction = {
  prefix: 'far',
  iconName: 'function',
  icon: [
    640,
    512,
    [],
    'f661',
    'M224 48c0-8.84-7.16-16-16-16h-48c-48.6 0-88 39.4-88 88v48H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h56v144c0 22.09-17.91 40-40 40H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h16c48.6 0 88-39.4 88-88V216h56c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16h-56v-48c0-22.09 17.91-40 40-40h48c8.84 0 16-7.16 16-16V48zm93.43 60.92l-12.8-9.63c-7.22-5.44-17.81-4.01-22.92 3.41C244.39 157 224 222.17 224 288c0 65.85 20.39 131.02 57.71 185.3 5.11 7.43 15.7 8.85 22.92 3.41l12.8-9.63c6.84-5.14 8.09-14.54 3.28-21.59C289.2 399.27 272 343.92 272 288c0-55.91 17.2-111.26 48.71-157.5 4.8-7.05 3.55-16.44-3.28-21.58zm264.86-6.22c-5.11-7.43-15.7-8.85-22.92-3.41l-12.8 9.63c-6.84 5.14-8.09 14.54-3.28 21.59C574.8 176.73 592 232.08 592 288c0 55.91-17.2 111.26-48.71 157.5-4.8 7.05-3.55 16.44 3.28 21.59l12.8 9.63c7.22 5.44 17.81 4.02 22.92-3.41C619.61 419 640 353.83 640 288c0-65.85-20.39-131.02-57.71-185.3zm-74.84 120.84l-10.99-10.99c-6.07-6.07-15.91-6.07-21.98 0L432 255.03l-42.47-42.47c-6.07-6.07-15.91-6.07-21.98 0l-10.99 10.99c-6.07 6.07-6.07 15.91 0 21.98L399.03 288l-42.47 42.47c-6.07 6.07-6.07 15.91 0 21.98l10.99 10.99c6.07 6.07 15.91 6.07 21.98 0L432 320.97l42.47 42.47c6.07 6.07 15.91 6.07 21.98 0l10.99-10.99c6.07-6.07 6.07-15.91 0-21.98L464.97 288l42.47-42.47c6.08-6.07 6.08-15.92.01-21.99z',
  ],
};
