// export const enum Id when supported
export const Id = {
  app: 'app',
  top: 'top',
  status: 'status',
  header: 'header',
  row: 'row',
  contentArea: 'content-area',
  leftAside: 'leftAside',
  rightAside: 'rightAside',
  sheet: 'sheet',
  footer: 'footer',
};

export interface AppLayoutProps {
  status?: React.ReactElement;
  navigationBar?: React.ReactElement;
  footer?: React.ReactElement;
  children?: React.ReactNode;
  skipLinks?: React.ReactElement;
}

export interface AppShellProps extends AppLayoutProps {}
