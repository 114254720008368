import React from 'react';
import {
  forwardRefWithAs,
  splitProps,
  systemProps,
  warning,
  SystemProps,
} from '@oms/ui-utils';

import { useCheckboxGroup } from './CheckboxGroup';
import * as S from './styles';

type InputProps = Omit<
  JSX.IntrinsicElements['input'],
  'color' | 'size' | 'width' | 'height'
>;

export interface CheckboxProps extends InputProps, SystemProps {}

export const Checkbox = forwardRefWithAs<CheckboxProps, 'input'>(
  function Checkbox({ className, ...props }, ref) {
    const [system, rest] = splitProps(props, systemProps as any);
    const name = useCheckboxGroup();
    warning(
      !props.name && !name,
      '[Checkbox]',
      'you can only use an unnamed Checkbox inside a CheckboxGroup',
    );
    return (
      <S.Container className={`Checkbox ${className}`} {...system}>
        <S.Input
          ref={ref}
          name={name}
          {...rest}
          type="checkbox"
          aria-checked={props.checked}
        />
        <S.Indicator role="presentation" data-indicator="" />
      </S.Container>
    );
  },
);

Checkbox.displayName = 'Checkbox';
