import React, { ReactNode } from 'react';
import { SystemProps, forwardRefWithAs } from '@oms/ui-utils';
import { useLink } from '@oms/ui-components-context';
import * as S from './styles';

export type LinkVariants =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'buy'
  | 'sell'
  | 'danger'
  | 'inherit'
  | 'plain';

type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface LinkProps extends SystemProps {
  size?: Sizes;
  /** Content left, e.g. <Icon /> @deprecated*/
  leftAddon?: ReactNode;
  /** Content right, e.g. <Icon /> @deprecated*/
  rightAddon?: ReactNode;
  /** Content left, e.g. <Icon /> */
  leftElement?: ReactNode;
  /** Content right, e.g. <Icon /> */
  rightElement?: ReactNode;
  variant?: LinkVariants;
  disabled?: boolean;
  to?: any;
}

const anchor = {
  rel: 'noopener noreferrer',
  target: '_blank',
};

export const Link = forwardRefWithAs<LinkProps, 'a'>(function Link(
  {
    children,
    leftAddon,
    rightAddon,
    leftElement = leftAddon,
    rightElement = rightAddon,
    variant = 'primary',
    size = 'md',
    disabled,
    onClick,
    title,
    'aria-label': ariaLabel,
    as,
    ...props
  },
  ref,
) {
  const LinkImpl = useLink();
  const handleDisabled = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
  };

  return (
    <S.Link
      as={as || LinkImpl}
      {...(as === 'a' ? anchor : {})}
      {...props}
      ref={ref}
      variant={variant}
      buttonSize={size}
      onClick={disabled ? handleDisabled : onClick}
      aria-disabled={disabled}
      aria-label={disabled ? `${ariaLabel} disabled` : ariaLabel}
      title={disabled ? `${title} disabled` : title}
    >
      {leftElement && (
        <S.Addon data-left="" mr={3}>
          {leftElement}
        </S.Addon>
      )}
      {children}
      {rightElement && (
        <S.Addon data-right="" ml={3}>
          {rightElement}
        </S.Addon>
      )}
    </S.Link>
  );
});
