import styled, { css } from '@xstyled/emotion';
import { DialogBackdropOptions, DialogBackdrop } from 'reakit/Dialog';
import { space, flexboxes, th, variant } from '@xstyled/system';
import { mixins, SystemProps, createSystemComponent } from '@oms/ui-utils';

export const Backdrop = styled(
  createSystemComponent(DialogBackdrop, [
    'alignItems',
    'justifyContent',
    'p',
    'orphan',
    'variant',
    'unstable_orphan',
  ]),
)`
  ${mixins.borderBox}
  ${variant({
    default: 'default',
    variants: {
      default: css`
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: backdrop;
      `,
      orphan: css`
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: backdrop-orphan;
        top: 0;
        left: 0;
        display: flex;
        padding: 16;
      `,
    },
  })}
  ${th(
    'backdrop',
  )}
  ${flexboxes}
  ${space}
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  &[data-enter] {
    opacity: 1;
  }
`;

export interface BackdropProps extends SystemProps, DialogBackdropOptions {}
