import React from 'react';
import { SystemProps } from '@oms/ui-utils';
import { SVG } from './styles';

const getName = (icon: IconDefinition) => {
  return icon.iconName;
};

const getPath = (icon: IconDefinition) => {
  return icon.icon[4] as string;
};

const getDimensions = (icon: IconDefinition) => {
  return { width: icon.icon[0] as number, height: icon.icon[1] as number };
};

/** Just here to try and get Jenkins up and running again */
export type IconType = {};

type IconDefinition = {
  prefix: string;
  iconName: string;
  icon: (string | number | never[])[];
};

type SVGFilteredProps = Omit<
  React.SVGProps<SVGSVGElement>,
  | 'color'
  | 'width'
  | 'display'
  | 'fontFamily'
  | 'fontWeight'
  | 'fontSize'
  | 'height'
  | 'letterSpacing'
  | 'opacity'
  | 'order'
  | 'overflow'
>;
export interface IconProps extends SVGFilteredProps, SystemProps {
  icon: IconDefinition;
  rotation?: 0 | 45 | 90 | 135 | 180 | 270;
  size?:
    | 'xs'
    | 'lg'
    | 'sm'
    | 'md'
    | '1x'
    | '2x'
    | '3x'
    | '4x'
    | '5x'
    | '6x'
    | '7x'
    | '8x'
    | '9x'
    | '10x';
}

export function Icon({
  icon,
  rotation = 0,
  color,
  size = 'md',
  ...props
}: IconProps) {
  const { width = 512, height = 512 } = getDimensions(icon),
    path = getPath(icon) || '',
    name = getName(icon) || '';
  return (
    <SVG
      focusable="false"
      role="img"
      aria-hidden="true"
      style={{ transform: `rotate(${rotation}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      data-icon={name}
      data-size={size}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <path color="currentColor" d={path} />
    </SVG>
  );
}

Icon.displayName = 'Icon';
