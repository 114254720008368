import React, { FC } from 'react';
import { ThemeProvider, ColorModeProvider } from '@xstyled/emotion';
import { theme, BoxSizingStyles } from '@oms/ui-theming';
import { MediaProvider } from '@oms/ui-media';
import { ElementQueryProvider } from '@oms/ui-element-query';
import {
  ComponentsProvider,
  ComponentsProviderProps,
  defaultContextValue as defaultComponentsValue,
} from '@oms/ui-components-context';
import {
  LanguageProvider,
  LanguageProviderProps,
  defaultContextValue as defaultLanguageValue,
} from '@oms/ui-language-context';

export interface UIProviderProps {
  components?: Omit<ComponentsProviderProps, 'children'>;
  theme?: Partial<typeof theme>;
  language?: LanguageProviderProps['language'];
  /** A (Reset) component containing for example global styles */
  global?: React.ReactElement;
}

export const UIProvider: FC<UIProviderProps> = ({
  children,
  components,
  theme: passedTheme = theme,
  language = navigator.language ?? 'en',
  global = <BoxSizingStyles />,
}) => {
  return (
    <LanguageProvider language={language}>
      <ThemeProvider theme={passedTheme}>
        <ColorModeProvider>
          {global}
          <MediaProvider>
            <ElementQueryProvider>
              <ComponentsProvider {...components}>
                {children}
              </ComponentsProvider>
            </ElementQueryProvider>
          </MediaProvider>
        </ColorModeProvider>
      </ThemeProvider>
    </LanguageProvider>
  );
};

export { defaultComponentsValue, defaultLanguageValue };
