import { css } from '@xstyled/emotion';

export const buttonBase = (props: { isPending: boolean }) => css`
  appearance: none;
  margin: 0;
  display: inline-block;
  position: relative;
  flex-shrink: 0;
  text-align: center;
  width: auto;
  font-family: body;
  font-weight: normal;
  line-height: none;
  border-color: transparent;
  border-width: 0;
  border-style: solid;
  color: inherit;
  user-select: none;
  cursor: ${props.isPending ? 'progress' : 'pointer'};
  :focus {
    outline: none !important;
    box-shadow: focus;
  }
  :disabled,
  &[aria-disabled='true'] {
    color: inverse-text;
    background-color: disabled;
    border-color: disabled;
    cursor: not-allowed;
    &:focus,
    &:hover {
      background-color: disabled;
    }
  }
`;
