import styled from '@xstyled/emotion';
import { th } from '@xstyled/system';
import * as R from 'reakit/Popover';
import { mixins } from '@oms/ui-utils';
import { Stack } from '@oms/ui-stack';

export const PopoverPanel = styled(R.Popover)`
  ${mixins.borderBox}
  ${mixins.panel}
  ${th('text.variants.body2')}
  ${th('panel')}
  padding: 3;
  border: sm;
  border-color: border;
  border-radius: sm;
  max-width: 30rem;
  z-index: 1;
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  &[data-enter] {
    opacity: 1;
  }
  :focus {
    box-shadow: focus;
    outline: none !important;
  }
  ${th('popover.panel')}
`;

export const PopoverArrow = styled(R.PopoverArrow)`
  fill: surface-1;
  svg .stroke {
    fill: border;
  }
  ${th('popover.arrow')}
`;

export const Actions = styled(Stack)`
  justify-content: flex-end;
  border-top: sm;
  border-top-color: border;
  margin: 2 -3 -3;
  padding: 3 3;
  ${th('popover.actions')}
`;
Actions.defaultProps = {
  orientation: 'horizontal',
};
