import { default as moment, Moment } from 'moment';

/**
 * Formats a date to a string using moment.
 * @param {string|moment} date - The date to format.
 * @param {string[]|string} [format=['HH:mm', 'DD. MMM', 'DD. MMM YYYY']] - A moment.js format string or strings. When
 * an array it takes the shape of `[todayFormat, thisYearFormat, elseFormat]`.
 * The default value is the OMS standard values for date formatting.
 * @param {string} [locale='nb'] - The locale to use.
 * @return {string} The formatted date.
 */
export default function formatDate(
  date: Moment,
  format: string[] | string = ['HH:mm', 'DD. MMM', 'DD. MMM YYYY'],
  locale = 'nb',
) {
  if (!moment.isMoment(date)) {
    date = moment(date);
  }

  date.locale(locale);

  if (typeof format === 'string') {
    return date.format(format);
  }

  if (Array.isArray(format)) {
    // Lengths of 2 and 3 are supported
    if (format.length < 2 || format.length > 3) {
      throw new Error(
        'formatDate: Unexpected format length. Expected 3, got: ' +
          format.length,
      );
    }

    if (moment().isSame(date, 'day')) {
      return date.format(format[0]);
    }

    if (format.length === 3 && moment().isSame(date, 'year')) {
      return date.format(format[1]);
    }

    return date.format(format[format.length - 1]);
  }

  throw new Error(`formatDate: Unknown format ${format}, (${typeof format})`);
}
