import React from 'react';
import { SystemProps } from '@oms/ui-utils';
import { HighlighterProps } from 'react-highlight-words';
import * as S from './styles';

type TextVariants =
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'subtitle1'
  | 'subtitle2'
  | 'button'
  | 'caption'
  | 'label'
  | 'message'
  | 'error';

export interface HighlightedTextProps extends Omit<SystemProps, 'as'> {
  variant?: TextVariants;
  uppercase?: boolean;
  capitalize?: boolean;
  children?: string;
  search: string;
  autoEscape?: HighlighterProps['autoEscape'];
}

export function HighlightedText({
  variant = 'body2',
  children,
  search,
  ...props
}: HighlightedTextProps) {
  return (
    <S.HighlightedText
      variant={variant}
      autoEscape={true}
      textToHighlight={children}
      searchWords={search.trim().split(' ')}
      {...(props as Omit<HighlightedTextProps, 'color'>)}
    />
  );
}
