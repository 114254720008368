/** @jsx jsx */
import React from 'react';
import styled, { css, jsx } from '@xstyled/emotion';
import {
  jsxCloneElement,
  SystemProps,
  forwardRefWithAs,
  ResponsiveValue,
  Space,
  mixins,
} from '@oms/ui-utils';
/* Flatten children removes fragments, null, etc */
import flattenChildren from 'react-flatten-children';

const Container = styled.box`
  ${mixins.borderBox}
  ${mixins.borderWidth}
  ::before {
    display: block;
    content: '';
  }
`;
const InnerContainer = styled.box`
  ${mixins.borderBox}
  display: flex;
  flex-wrap: wrap;
`;

export const WrapItem = styled.box`
  ${mixins.borderBox}
`;

export interface WrapProps extends SystemProps {
  gap: ResponsiveValue<Space>;
}

/**
 * If you need to render multiple components in a row with equal spacing around them, wrapping onto multiple lines when necessary,
 * OMS UI provides an `Wrap` component. The `Wrap` component when combined with the `flex` prop on children
 * can also be used to create complex responsive layouts with ease.
 */
export const Wrap = forwardRefWithAs<WrapProps, 'div'>(function Wrap(
  {
    gap = 3,
    as = 'div',
    children,
    width,
    height,
    minWidth,
    minHeight,
    flex,
    ...props
  },
  ref,
) {
  return (
    <Container
      width={width}
      height={height}
      minWidth={minWidth}
      minHeight={minHeight}
      flex={flex}
      css={css`
        ::before {
          /** Counter the margin-top applied to Item */
          margin-top: -${gap};
        }
      `}
    >
      <InnerContainer
        as={as}
        css={css`
          /** Counter the margin-left applied to Item */
          margin-left: -${gap};
        `}
        ref={ref}
        {...props}
      >
        {React.Children.map(flattenChildren(children), (child) => {
          const flex = child.props.flex;
          return (
            <WrapItem flex={flex}>
              {jsxCloneElement(child, {
                flex: undefined,
                css: css`
                  /* This creates the gap */
                  margin-top: ${gap};
                  margin-left: ${gap};
                `,
              })}
            </WrapItem>
          );
        })}
      </InnerContainer>
    </Container>
  );
});
