import * as React from 'react';
import { Suggest } from './Suggest';

const defaultContextValue = (
  props: React.ComponentPropsWithoutRef<typeof Suggest>,
) => <Suggest {...props} onSuggestionSelected={props.onSuggestionSelected} />;

export const Context = React.createContext<typeof Suggest>(defaultContextValue);
const { Provider, Consumer } = Context;
const useSuggest = () => React.useContext(Context);

export { Provider, Consumer, defaultContextValue, useSuggest };
