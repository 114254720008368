import React from 'react';
import styled, { css } from '@xstyled/emotion';
import { th, variant } from '@xstyled/system';
import { mixins } from '@oms/ui-utils';

const trackColor = (props: any) =>
  props.trackColor
    ? css`
        border-color: ${props.trackColor};
      `
    : ``;

const segmentColor = (props: any) =>
  props.segmentColor
    ? css`
        border-top-color: ${props.segmentColor};
      `
    : ``;

export const StyledSpinner = styled.div`
  ${mixins.borderBox}
  display: block;
  cursor: wait;
  border-radius: 9999px;
  border-style: solid;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: spin;
  ${th('spinner')}
  ${trackColor}
  ${segmentColor}

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ${variant({
    prop: 'size',
    default: 'md',
    variants: {
      sm: css`
        width: 1rem;
        height: 1rem;
        border-width: md;
      `,
      md: css`
        width: 2rem;
        height: 2rem;
        border-width: lg;
      `,
      lg: css`
        width: 3rem;
        height: 3rem;
        border-width: lg;
      `,
    },
  })}
`;

export interface SpinnerProps {
  size?: 'sm' | 'md' | 'lg';
  trackColor?: string;
  segmentColor?: string;
}

export function Spinner({
  size = 'md',
  trackColor,
  segmentColor,
}: SpinnerProps) {
  return (
    <StyledSpinner
      size={size}
      trackColor={trackColor}
      segmentColor={segmentColor}
    />
  );
}
