import * as React from 'react';
import { jsx } from '@xstyled/emotion';

export const jsxCloneElement = (
  element: JSX.Element & { ref: React.Ref<any | null> },
  props: any
) =>
  jsx(element.type, {
    key: element.key,
    ref: element.ref,
    ...element.props,
    ...props,
  });
