import React from 'react';
import styled from '@xstyled/emotion';
import {
  forwardRefWithAs,
  SystemProps,
  splitProps,
  systemProps,
} from '@oms/ui-utils';
import { ClickableHTMLProps, useClickable } from 'reakit/Clickable';
import { mixins } from '@oms/ui-utils';

type ClickableHTML = Omit<ClickableHTMLProps, 'color' | 'width'>;
interface ClickableProps extends ClickableHTML, SystemProps {}

const Box = styled.box`
  ${mixins.borderBox}
  ${mixins.borderWidth}
  cursor:pointer;
`;

const Clickable = forwardRefWithAs<ClickableProps, 'div'>(function Clickable(
  { children, as = 'div', disabled, ...props },
  ref,
) {
  const [system, html] = splitProps(props, systemProps as any);
  const clickable = useClickable({ disabled }, { ref, ...html });
  return (
    <Box as={as} {...system} {...clickable}>
      {children}
    </Box>
  );
});

export { Clickable, ClickableProps };
