import React from 'react';
import styled from '@xstyled/emotion';
import { variant } from '@xstyled/system';
import { mixins, useId, SystemProps } from '@oms/ui-utils';
import { Popover } from '@oms/ui-popover';
import { Icon, light } from '@oms/ui-icon';

export const Button = styled.buttonBox`
  ${mixins.borderBox}
  ${mixins.button}
  ${mixins.textButton}
  font-size: 0.875rem;
  ${variant({
    prop: 'variant',
    key: 'infoBubble.variants',
    default: 'default',
  })}
  cursor:help;
`;
export interface InfoBubbleProps extends SystemProps {
  'aria-label': string;
  children: React.ReactNode;
  variant?: 'default' | 'label';
}

export function InfoBubble({
  children,
  'aria-label': ariaLabel = 'Info',
  variant = 'default',
  ...styleProps
}: InfoBubbleProps) {
  const id = useId('info-bubble');
  return (
    <>
      <Popover
        aria-labelledby={id}
        disclosure={
          <Button
            id={id}
            aria-label={ariaLabel}
            type="button"
            variant={variant}
            {...styleProps}
          >
            <Icon size={undefined} icon={light.faInfoCircle} />
          </Button>
        }
      >
        {children}
      </Popover>
    </>
  );
}
