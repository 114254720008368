/*! matchMedia() polyfill - Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas.
Dual MIT/BSD license https://github.com/paulirish/matchMedia.js   */

window.matchMedia =
  window.matchMedia ||
  (function (doc, undefined) {
    'use strict';

    var bool,
      docElem = doc.documentElement,
      refNode = docElem.firstElementChild || docElem.firstChild,
      // fakeBody required for <FF4 when executed in <head>
      fakeBody = doc.createElement('body'),
      div = doc.createElement('div');

    div.id = 'mq-test-1';
    div.style.cssText = 'position:absolute;top:-100em';
    fakeBody.style.background = 'none';
    fakeBody.appendChild(div);

    var _matchMedia = function (query) {
      div.innerHTML =
        '&shy;<style media="' +
        query +
        '"> #mq-test-1 { width: 42px; }</style>';

      docElem.insertBefore(fakeBody, refNode);
      bool = div.offsetWidth === 42;
      docElem.removeChild(fakeBody);

      return {
        matches: bool,
        media: query,
      };
    };

    // Listener

    var listeners = [],
      last = false,
      timer,
      check = function () {
        var list = _matchMedia(q),
          unmatchToMatch = list.matches && !last,
          matchToUnmatch = !list.matches && last;

        // fire callbacks only if transitioning to or from matched state
        if (unmatchToMatch || matchToUnmatch) {
          for (var i = 0, il = listeners.length; i < il; i++) {
            listeners[i].call(ret, list);
          }
        }
        last = list.matches;
      };

    _matchMedia.addListener = function (cb) {
      // TODO: Bind to window.onresize instead
      listeners.push(cb);
      if (!timer) {
        timer = setInterval(check, 1000);
      }
    };

    _matchMedia.removeListener = function (cb) {
      for (var i = 0, il = listeners.length; i < il; i++) {
        if (listeners[i] === cb) {
          listeners.splice(i, 1);
        }
      }
      if (!listeners.length && timer) {
        clearInterval(timer);
      }
    };

    return _matchMedia;
  })(document);
