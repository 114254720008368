import { useEffect, useRef, AriaAttributes } from 'react';
import { DataType, TdOptions } from './types';

const POS = 'pos';
const NEG = 'neg';
const FLAT = 'flat';

const valueToText = (number: string | number) => {
  const text = {
    '-1': NEG,
    '0': FLAT,
    '1': POS,
  };
  return (text as any)[number.toString() as any];
};

export const getTrend = (value: number) =>
  valueToText(Math.sign(value).toString(10));

export const getFlash = (value: number, previous: number) => {
  return value > previous ? POS : value < previous ? NEG : FLAT;
};

const isNumeric = (type: string | undefined) =>
  type === 'number' || type === 'numberWithUnit' || type === 'percent';

type DataAttributes = TdOptions & { previousValue?: number; type?: string };
export const getDataAttributes = ({
  trend,
  flash,
  suffix,
  prefix,
  value,
  previousValue,
  truncate,
  type,
}: DataAttributes) => {
  const attributes: any = {
    'data-type': type,
  };
  if (truncate) Object.assign(attributes, { 'data-truncate': '' });
  if (prefix) Object.assign(attributes, { 'data-prefix': `${prefix} ` });
  if (suffix) Object.assign(attributes, { 'data-suffix': ` ${suffix}` });
  if (trend && value)
    Object.assign(attributes, { 'data-trend': getTrend(value) });
  if (flash && value && previousValue)
    Object.assign(attributes, {
      'data-flash': getFlash(value, previousValue as number),
    });

  return attributes;
};

export const useAttributes = ({
  trend,
  flash,
  suffix,
  prefix,
  value,
  previousValue,
  truncate,
  type,
}: DataAttributes) => {
  const flag = useRef<boolean>(false);

  const attributes: any = {
    'data-type': type,
  };
  if (truncate) Object.assign(attributes, { 'data-truncate': '' });
  if (prefix) Object.assign(attributes, { 'data-prefix': `${prefix} ` });
  if (suffix) Object.assign(attributes, { 'data-suffix': ` ${suffix}` });
  if (((value as unknown) as string) !== '-') {
    if (trend && value)
      Object.assign(attributes, { 'data-trend': getTrend(value) });
    if (flash && value && previousValue) {
      Object.assign(attributes, {
        'data-flash': flag.current
          ? getFlash(value, previousValue as number)
          : getFlash(value, previousValue as number) + '-2',
      });
      flag.current = !flag.current;
    }
  }

  return attributes;
};

export const shouldApplyChangeClass = (
  previousValue: any,
  currentValue: any,
  type?: DataType,
) => {
  return isNumeric(type) && previousValue && previousValue !== currentValue;
};

/**
 * Aria attributes to apply on a sortable th
 * @param isSorted
 * @param isSortedDesc
 */
export const getAriaSortValue = (
  isSorted: boolean,
  isSortedDesc: boolean | undefined,
) => {
  const sort: AriaAttributes['aria-sort'] = isSorted
    ? isSortedDesc
      ? 'descending'
      : 'ascending'
    : 'none';
  return sort;
};

/**
 * Aria attributes to apply to a sort Icon
 * @param isSorted
 * @param isSortedDesc
 */
export const getAriaLabelValue = (
  isSorted: boolean,
  isSortedDesc: boolean | undefined,
) =>
  isSorted
    ? isSortedDesc
      ? 'Unsort rows by this header'
      : 'Sort rows by this header in ascending order'
    : 'Sort rows by this header in descending order';

/**
 * Based on https://usehooks.com/useWhyDidYouUpdate/
 *
 * @param name a key to identify console group
 * @param props object to persist and compare
 */
export function useWhyDidYouUpdate(
  use: boolean,
  name: string,
  props: Record<string, unknown>,
) {
  const previousProps = useRef<Record<string, unknown>>();

  useEffect(() => {
    if (use && __DEV__) {
      const { current } = previousProps;
      if (current) {
        const allKeys = Object.keys({ ...current, ...props });
        const changesObj: Record<string, unknown> = {};
        allKeys.forEach((key) => {
          if (current[key] !== props[key]) {
            changesObj[key] = {
              from: current[key],
              to: props[key],
            };
          }
        });

        console.group(
          `%c[why-did-you-update] %c${name}`,
          'font-weight: bold;',
          'color: dodgerblue; font-weight: bold;',
        );
        if (Object.keys(changesObj).length) {
          console.table(changesObj);
        } else {
          console.log('Something else changed.');
        }
        console.groupEnd();
      }

      previousProps.current = props;
    }
  });
}
