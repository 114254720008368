import React from 'react';
import { AppShellProvider } from './AppShellContext';
import { AppLayout } from './layouts';
import { AppShellProps } from './types';

export function AppShell(props: AppShellProps) {
  return (
    <AppShellProvider>
      <AppLayout {...props} />
    </AppShellProvider>
  );
}
