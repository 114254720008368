import React, { useState, useRef } from 'react';
import { useCombobox, UseComboboxProps } from 'downshift';
import { useDebouncedCallback } from 'use-debounce';
import { usePopper } from 'react-popper-2';
import { Input, TextInputWrapper } from '@oms/ui-text-input';
import { Icon, light } from '@oms/ui-icon';
import { ClearButton } from '@oms/ui-icon-button';
import { HighlightedText } from '@oms/ui-highlighted-text';
import {
  forwardRefWithAs,
  splitProps,
  noop,
  systemProps,
  useId,
  useForkRef,
} from '@oms/ui-utils';
import {
  Container,
  ListBox,
  ItemContainer,
  GroupHeader,
  GroupList,
  Group,
  defaultItemToString,
  getSelected,
  groupBy,
  Item,
} from '@oms/ui-select';
import { RenderItem } from './RenderItem';
import * as S from './styles';

type DownshiftProps = Omit<UseComboboxProps<Item>, 'onInputValueChange'>;

export interface SearchProps extends DownshiftProps {
  /** Is the Search box clearable */
  isClearable?: boolean;
  disabled?: boolean;
  renderItem?: typeof RenderItem;
  /**
   * Called each time the selected option has changed.
   * Selection can be performed by option click,
   * Enter Key while option is highlighted or by blurring the menu
   * while an option is highlighted (Tab, Shift-Tab or clicking away).
   */
  onChange?: (event?: any) => void;
  /**
   * Called each time the input value has changed.
   */
  onInputValueChange?: (changes?: string | number) => void;
  /** Handle focus events on the Search component */
  onBlur?: (event?: any) => void;
  /** Handle focus events on the Search component */
  onFocus?: (event?: any) => void;
  id?: string;
  name?: string;
  labelId?: string;
  /** Placeholder for the Combobox/Select if value is undefined */
  placeholder?: string;
  /** Aria label (for assistive tech) */
  ariaClearButtonLabel?: string;
  /** Id of the describing element (for assistive tech)  */
  'aria-describedby'?: string;
  /** Id of the describing error element (for assistive tech)  */
  'aria-errormessage'?: string;
  'aria-invalid'?: boolean;
  /** The minimum amount of characters the user has to type before the onInputChange/fetcher callback is called */
  minimumQueryLength?: number;
  /** Message to show when the minimum amount of query characters has not been typed yet*/
  minimumQueryLengthMessage?: string;
  /** Message to show when no items are found/matched */
  noDataMessage?: string;
  /** Message to show when querying resulted in a error */
  errorMessage?: string;
  /**
   * Match sorter options
   * @see https://github.com/kentcdodds/match-sorter
   */
  // matchSorterOptions?: boolean | object;
  debounce?: number;
  /**
   * Can be:
   * - **idle** if the query is idle.
   * - **loading** if the query is in a loading state.
   * - **error** if the query attempt resulted in an error.
   * - **success** if the query has received a response with no errors and is ready to display its data.
   */
  status?: 'idle' | 'loading' | 'error' | 'success';
  /** Property which should be used to group items by */
  groupByKey?: string;
  groupToString?: (groupValue: string) => string;
  /** Expose some internal methods for fine grained control of the component */
  imperativeHandle?: React.Ref<null | { reset: () => void }>;
  /** When hitting Tab the focussed item will be selected. By default this is the first item  */
  enableTabToSelect?: boolean;
}

export const Search = forwardRefWithAs<SearchProps, 'input'>(function Search(
  {
    value,
    onChange,
    onBlur = noop,
    onFocus = noop,
    onInputValueChange = noop,
    onSelectedItemChange = noop,
    disabled,
    isClearable,
    renderItem: ItemRenderer = RenderItem,
    id,
    labelId,
    placeholder,
    ariaClearButtonLabel = 'Clear selection option',
    'aria-describedby': describedBy,
    'aria-invalid': invalid,
    'aria-errormessage': ariaErrorMessage,
    required,
    items = [],
    itemToString = defaultItemToString,
    debounce = 300,
    status,
    minimumQueryLength = 3,
    noDataMessage = 'No match found for query ',
    minimumQueryLengthMessage = `Type ${minimumQueryLength} or more characters to start searching `,
    errorMessage = 'Oops something went wrong',
    groupByKey,
    groupToString = (group: string) => group,
    imperativeHandle,
    enableTabToSelect = false,
    ...props
  },
  forwardedRef,
) {
  const innerId = useId();
  const [system, rest] = splitProps(props, systemProps as any);

  const [debouncedOnInputValueChange] = useDebouncedCallback(
    onInputValueChange,
    debounce,
  );

  /*
   *  Using useState instead of useRef. useState effectively provides a callback ref,
   *  which triggers an update in usePopper as the ref is assigned.
   */
  const [element, registerElement] = useState<HTMLElement | null>(null);

  const [popper, registerPopper] = useState<HTMLElement | null>(null);

  const { styles, attributes, forceUpdate } = usePopper(element, popper, {
    placement: 'bottom-start',
  });

  const ref = useRef<HTMLInputElement>(null);
  const forkedRef = useForkRef(forwardedRef, ref);

  const {
    isOpen,
    selectedItem,
    inputValue,
    highlightedIndex,
    getComboboxProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    reset,
  } = useCombobox({
    labelId,
    inputId: id,
    items: groupByKey
      ? Object.values(groupBy(items, groupByKey)).flat()
      : items,
    itemToString,
    defaultHighlightedIndex: 0,
    onStateChange: ({ type, selectedItem, inputValue }: any) => {
      switch (type) {
        //  @ts-ignore falls through
        case useCombobox.stateChangeTypes.InputBlur:
          if (enableTabToSelect && selectedItem && onChange) {
            onChange(selectedItem);
          }
          return;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.FunctionSelectItem:
          if (selectedItem && onChange) {
            onChange(selectedItem);
          }
          break;
        case useCombobox.stateChangeTypes.InputChange:
          // Only runs when the user types in the input field
          if (inputValue.length >= minimumQueryLength) {
            debouncedOnInputValueChange(inputValue);
          }
          break;
        case useCombobox.stateChangeTypes.FunctionReset:
          if (onChange) {
            ref?.current?.focus?.();
            onChange('');
          }
          break;
        default:
          break;
      }
    },
    stateReducer: (state, actionsAndChanges) => {
      const { type, changes } = actionsAndChanges;
      switch (type) {
        /**
         * This allows use to highlight the first item,
         * but only select the item if clicked/on key down enter
         */
        case useCombobox.stateChangeTypes.InputBlur:
          if (enableTabToSelect) {
            return changes;
          } else {
            return {
              ...state,
              isOpen: false,
              highlightedIndex: -1,
            };
          }
        default:
          return changes;
      }
    },
  });

  React.useEffect(() => {
    if (isOpen) {
      forceUpdate?.();
    }
  }, [isOpen, forceUpdate]);

  const showMinimumQueryLengthMessage = inputValue?.length < minimumQueryLength;
  const showNoDataMessage =
    items?.length === 0 &&
    inputValue?.length >= minimumQueryLength &&
    status === 'success';
  const showErrorMessage = status === 'error';

  React.useImperativeHandle(
    imperativeHandle,
    () => ({
      reset,
    }),
    [imperativeHandle, reset],
  );

  return (
    <Container {...system}>
      <TextInputWrapper
        data-state={(disabled && 'disabled') || (invalid && 'error')}
        {...getComboboxProps({
          ref: registerElement,
          style: {
            display: 'flex',
            //  height: 'auto',
            //  minHeight: '2.5rem',
          },
        })}
      >
        <Icon style={{ marginLeft: '0.5rem' }} icon={light.faSearch} />
        <Input
          {...getInputProps({
            ref: forkedRef,
            placeholder,
            onFocus,
            onBlur,
            'aria-describedby': describedBy,
            'aria-invalid': invalid,
            'aria-errormessage': ariaErrorMessage || `${innerId}-error-message`,
            spellCheck: false,
            autoCapitalize: 'off',
            autoCorrect: 'off',
            maxLength: 2048,
            ...rest,
          })}
        />
        {isClearable && selectedItem && <ClearButton onClick={reset} />}
        {status === 'loading' && <S.Spinner />}
        {status === 'error' && <Icon icon={light.faExclamationCircle} mr={2} />}
      </TextInputWrapper>
      <ListBox
        isOpen={isOpen}
        {...getMenuProps({
          ref: registerPopper,
          style: styles.popper,
          ...attributes.popper,
        })}
      >
        {!isOpen
          ? null
          : groupByKey
          ? Object.entries(groupBy(items, groupByKey)).reduce(
              (
                result = { sections: [], itemIndex: 0 },
                [group, items],
                groupIndex,
              ) => {
                result.sections.push(
                  <Group key={groupIndex}>
                    <GroupHeader>{groupToString(group)}</GroupHeader>
                    <GroupList>
                      {items.map((item, itemIndex) => {
                        const isSelected = getSelected(
                            item,
                            selectedItem,
                            itemToString,
                          ),
                          index = result.itemIndex++,
                          isHighlighted = highlightedIndex === index;
                        return (
                          <ItemRenderer
                            key={`${groupToString(group)}-${itemIndex}`}
                            item={item}
                            inputValue={inputValue}
                            isHighlighted={isHighlighted}
                            isSelected={isSelected}
                            itemToString={itemToString}
                            // inversion
                            ItemContainer={ItemContainer}
                            HighLightedText={HighlightedText}
                            {...getItemProps({
                              item,
                              index,
                            })}
                          />
                        );
                      })}
                    </GroupList>
                  </Group>,
                );
                return result;
              },
              { sections: [] as JSX.Element[], itemIndex: 0 },
            ).sections
          : items.map((item, index) => {
              const isSelected = getSelected(item, selectedItem, itemToString),
                isHighlighted = highlightedIndex === index;

              return (
                <ItemRenderer
                  key={`select-item-${index}`}
                  item={item}
                  inputValue={inputValue}
                  isHighlighted={isHighlighted}
                  isSelected={isSelected}
                  itemToString={itemToString}
                  // inversion
                  ItemContainer={ItemContainer}
                  HighlightedText={HighlightedText}
                  {...getItemProps({
                    item,
                    index,
                  })}
                />
              );
            })}
        {showNoDataMessage && (
          <ItemContainer>
            {noDataMessage}{' '}
            <b style={{ fontWeight: 'bold', marginLeft: '0.25rem' }}>
              {inputValue}
            </b>
          </ItemContainer>
        )}
        {showMinimumQueryLengthMessage && (
          <ItemContainer>{minimumQueryLengthMessage}</ItemContainer>
        )}
        {showErrorMessage && (
          <ItemContainer id={`${innerId}-error-message`}>
            {errorMessage}
          </ItemContainer>
        )}
      </ListBox>
    </Container>
  );
});

export function useSearchImperativeHandle() {
  const ref = React.useRef<{
    reset: () => void;
  } | null>(null);
  return ref;
}
