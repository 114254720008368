import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { forwardRefWithAs, useMediaQuery } from '@oms/ui-utils';
import {
  useDialogState,
  Dialog,
  DialogDisclosure,
  DialogStateReturn,
  DialogInitialState,
} from 'reakit/Dialog';
import { AppRightRegionPortal, useAppShell } from '@oms/ui-app-shell';
import { CloseButton } from '@oms/ui-icon-button';
import { S } from '@oms/ui-drawer';
import { StyledDialog } from './styles';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const SHEET_HEADER_ID = Symbol('sheetHeaderId');
const getHeaderId = (dialog: { baseId: string }) => `${dialog.baseId}-header`;

export const showSheetAndHideOthers = (
  target: DialogStateReturn,
  others: DialogStateReturn[],
) => {
  others.forEach((sheet) => {
    if (sheet.visible) {
      sheet.hide();
    }
  });
  if (!target.visible) {
    target.show();
  }
};

///////////////////////////////////////////////////////////////////////

interface SheetInitializeOptions extends DialogInitialState {
  /** Initial sheet state */
  initialState?: Record<string, any>;
  /** Viewport width, e.g. "26.25em" (iPhone 11 Pro Max) */
  modalViewportWidth?: string;
  placement?: 'left' | 'right';
}

/**
 * Hook for initializing a sheet.
 */
export const useInitializeSheet = ({
  initialState,
  modalViewportWidth: maxWidth = '26.25em',
  ...options
}: SheetInitializeOptions = {}) => {
  const isMobile = useMediaQuery({ maxWidth });
  const [state, setState] = useState(() => initialState);
  const sheet = useDialogState({
    ...options,
    modal: isMobile,
    animated: true,
  });
  useEffect(() => {
    /* Update modal state when the viewport changes */
    if (isMobile) {
      /* Modal will be reinitialized and rendered in a portal with focus trap. */
      sheet.setModal(true);
    } else {
      sheet.setModal(false);
    }
  }, [isMobile, sheet.setModal]);
  return { sheet, state, setState };
};

///////////////////////////////////////////////////////////////////////

type SheetReturnType = {
  sheet: DialogStateReturn;
  state: any;
  setState: React.Dispatch<any>;
};
export interface SheetProviderProps {
  sheets: Record<string, SheetReturnType>;
  children: React.ReactNode;
  /** Sheet top offset, used to determine the maximum height of a sheet */
  topOffset?: number;
}

const SheetContext = createContext<Record<string, SheetReturnType>>({});
SheetContext.displayName = 'SheetContext';

export const useSheets = () => useContext(SheetContext);

export const useSheetById = (sheetId: string) => {
  return useSheets()[sheetId];
};

export const useNavigateSheet = () => {
  const sheets = useSheets();
  const callback = useCallback(
    (sheetId: string, state: any) => {
      const { [sheetId]: match, ...otherSheets } = sheets;
      const { sheet, setState } = match;
      setState(state);
      showSheetAndHideOthers(
        sheet,
        Object.keys(otherSheets).map((key) => otherSheets[key].sheet),
      );
    },
    [sheets],
  );
  return callback;
};

export const SheetProvider = ({ children, sheets }: SheetProviderProps) => {
  const outerContext = useContext(SheetContext) || {};
  return (
    <SheetContext.Provider value={{ ...outerContext, ...sheets }}>
      {children}
    </SheetContext.Provider>
  );
};

///////////////////////////////////////////////////////////////////////

export interface SheetDisclosureProps {
  sheetId: string;
  state?: any;
}
/**
 * Accessible Disclosure component that controls visibility of a section of content (Sheet)
 */
export const SheetDisclosure = forwardRefWithAs<SheetDisclosureProps, 'button'>(
  ({ sheetId, state, ...props }, ref) => {
    const { [sheetId]: match, ...otherSheets } = useSheets();
    const { sheet, setState } = match;
    const toggle = useCallback(() => {
      setState(state);
      showSheetAndHideOthers(
        sheet,
        Object.keys(otherSheets).map((key) => otherSheets[key].sheet),
      );
    }, [state, sheet, otherSheets, setState]);

    return <DialogDisclosure ref={ref} {...props} {...sheet} toggle={toggle} />;
  },
);

///////////////////////////////////////////////////////////////////////

/**
 * We have some components (Header, CloseButton) that play nice inside of a Sheet.
 * To allow for arbitrary nesting/placement of these components, we hook them
 * into the SheetIdContext.
 */
const SheetIdContext = createContext<string>('');
export const useSheetId = () => {
  return useContext(SheetIdContext);
};

export interface SheetProps {
  sheetId: string;
  children: React.ReactNode;
  'aria-label'?: string;
  'aria-labelledby'?: string | typeof SHEET_HEADER_ID;
  style?: Record<string, string>;
}
export const Sheet = ({
  sheetId,
  children,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  style = {},
}: SheetProps) => {
  const { sheet } = useSheetById(sheetId);
  const { top } = useAppShell();
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const scrollBox = ref.current!;
    if (sheet.modal && sheet.visible) {
      disableBodyScroll(scrollBox);
    }
    return () => enableBodyScroll(scrollBox);
  }, [sheet.modal, sheet.visible]);

  if (sheet.modal === false) {
    return (
      <AppRightRegionPortal isSheet={true}>
        <Dialog
          data-sheet="true"
          ref={ref}
          {...sheet}
          hideOnClickOutside={false}
          aria-label={ariaLabel}
          aria-labelledby={
            ariaLabelledBy === SHEET_HEADER_ID
              ? getHeaderId(sheet)
              : ariaLabelledBy
          }
        >
          {(dialogProps) => (
            <SheetIdContext.Provider value={sheetId}>
              <StyledDialog
                {...dialogProps}
                as="section"
                placement="right"
                style={{
                  ...style,
                  height: `calc(100vh - ${top?.height}px)`,
                }}
              >
                {children}
              </StyledDialog>
            </SheetIdContext.Provider>
          )}
        </Dialog>
      </AppRightRegionPortal>
    );
  }
  return (
    <Dialog
      data-sheet="true"
      ref={ref}
      {...sheet}
      aria-label={ariaLabel}
      aria-labelledby={
        ariaLabelledBy === SHEET_HEADER_ID ? getHeaderId(sheet) : ariaLabelledBy
      }
    >
      {(dialogProps) => (
        <SheetIdContext.Provider value={sheetId}>
          <StyledDialog
            as="section"
            {...dialogProps}
            data-drawer="true"
            data-placement="sheet"
            placement="sheet"
          >
            {children}
          </StyledDialog>
        </SheetIdContext.Provider>
      )}
    </Dialog>
  );
};

export interface SheetCloseButtonProps {
  'aria-label'?: string;
  title?: string;
}

export function SheetCloseButton({
  'aria-label': ariaLabel = 'Close modal',
  title,
}: SheetCloseButtonProps) {
  const sheetId = useContext(SheetIdContext);
  const { sheet } = useSheetById(sheetId);
  return (
    <CloseButton aria-label={ariaLabel} title={title} onClick={sheet.hide} />
  );
}

export interface SheetHeaderProps {
  children: React.ReactNode;
  action?: React.ReactNode;
  ariaCloseButtonLabel?: string;
  id?: string;
}
export const SheetHeader = ({
  action,
  children,
  ariaCloseButtonLabel = 'Close modal',
  id,
}: SheetHeaderProps) => {
  const sheetId = useContext(SheetIdContext);
  const { sheet } = useSheetById(sheetId);

  return (
    <S.Header aria-labelledby={id ?? getHeaderId(sheet)}>
      <S.Action>
        <CloseButton aria-label={ariaCloseButtonLabel} onClick={sheet.hide} />
      </S.Action>
      <S.Text id={id ?? getHeaderId(sheet)}>{children}</S.Text>
      <S.Action justifyContent="flex-end">{action}</S.Action>
    </S.Header>
  );
};

/**
 * @implements Box
 */
export const SheetContent = S.Content as typeof S.Content;
/**
 * @implements Box
 */
export const SheetActions = S.Actions; // as typeof Stack;
