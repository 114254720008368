import React, { createContext, useMemo, useContext } from 'react';
import { defaultContextValue } from './defaultContext';
import { ContextTypes, ComponentsProviderProps } from './types';

export const Context = createContext<ContextTypes>(defaultContextValue);
Context.displayName = 'ComponentsContext';

export function ComponentsProvider({
  children,
  useField = defaultContextValue.useField,
  Link = defaultContextValue.Link,
  ErrorBoundary = defaultContextValue.ErrorBoundary,
}: // Search/Suggest
ComponentsProviderProps) {
  const context = useMemo(
    () => ({
      useField,
      Link,
      ErrorBoundary,
      // Search/Suggest
    }),
    [useField, Link, ErrorBoundary],
  );
  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export const useComponents = () => {
  return useContext(Context);
};

export const useLink = () => {
  return useContext(Context)?.Link;
};

/**
 * useField exported by the form library used in your project, shared through context.
 * @example import { useField } from 'react-final-form'
 * @example import { useField } from 'formik'
 * */
export const useField = (name: string, config?: object) => {
  return useContext(Context)?.useField(name, config);
};

export const useErrorBoundary = () => {
  return useContext(Context)?.ErrorBoundary;
};
