import React from 'react';
import {
  mapProps,
  ResponsiveValue,
  Space,
  ReactNodeNoStrings,
  SystemProps,
  forwardRefWithAs,
} from '@oms/ui-utils';
import * as S from './styles';

const getFlexProp = mapProps({
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  stretch: 'stretch',
});

type AlignmentTypes = 'start' | 'center' | 'end' | 'stretch';
type OrientationTypes = 'vertical' | 'horizontal';
type StackSystemProps = Omit<SystemProps, 'gap' | 'alignment' | 'children'>;

export interface StackProps extends StackSystemProps {
  /**
   * The gap between items in the stack. Set to md by default.
   */
  gap?: ResponsiveValue<Space>;
  /**
   * Defines the distribution of the stack contents on the alternative axis to the direction.
   */
  alignment?: ResponsiveValue<AlignmentTypes>;
  /**
   * Orientation
   */
  orientation?: OrientationTypes;
  children?: ReactNodeNoStrings;
}

/**
 * Think of `Stack`-components as smart layout containers for your boxes. Add a few boxes, and they will
 * automatically be distributed within the Stack.
 * The `Stack` component is especially useful for quickly creating lists, forms and other layouts that require consistent vertical rhythm.
 *
 * Stack is Flexbox-based and can be used in projects which require IE support.
 *
 * Because `Stack` applies a margin to its decedents, child elements need to of type element. Plain strings and numbers are not allowed as children.
 *
 * @since 1.0.0
 */
export const Stack = forwardRefWithAs<StackProps, 'div'>(
  (
    { gap = 4, orientation = 'vertical', alignment = 'stretch', ...props },
    ref,
  ) => {
    const alignItems =
      typeof alignment === 'string'
        ? getFlexProp(alignment)
        : alignment &&
          (Object.entries(alignment).reduce((acc, [key, alignment]) => {
            return { ...acc, [key]: getFlexProp(alignment as AlignmentTypes) };
          }, {}) as ResponsiveValue<AlignmentTypes>);

    return (
      <S.Stack
        ref={ref}
        gap={gap}
        flexDirection={orientation === 'vertical' ? 'column' : 'row'}
        orientation={orientation}
        alignItems={alignItems}
        {...props}
      />
    );
  },
);
