import PropTypes from 'prop-types';
import { map } from 'react-immutable-proptypes';

const { object, bool, number } = PropTypes;

export default {
  items: map.isRequired,
  jaws: object.isRequired,
  initialized: bool.isRequired,
  totalSize: number.isRequired,
  emptyData: bool.isRequired,
  jawsFetching: bool.isRequired,
  hasData: bool.isRequired,
};
