import React, { createContext, useContext, ReactNode } from 'react';
import { noop } from '@oms/ui-utils';
import { Backdrop } from '@oms/ui-backdrop';
import { CloseButton } from '@oms/ui-icon-button';
import * as R from 'reakit/Dialog';
import * as S from './styles';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const DRAWER_HEADER_ID = Symbol('drawerHeaderId');
const getHeaderId = (dialog: { baseId: string }) => `${dialog.baseId}-header`;

type DrawerPosition = 'top' | 'right' | 'bottom' | 'left' | 'sheet';

type DrawerState = {
  dialog: any;
  placement: DrawerPosition;
};

const DrawerContext = createContext<DrawerState>({} as any);
DrawerContext.displayName = 'DrawerContext';
export const useDrawer = (options?: Parameters<typeof R.useDialogState>[0]) =>
  R.useDialogState({ animated: true, ...options });

export interface DrawerProps
  extends Omit<R.DialogProps, 'unstable_initialFocusRef' | 'aria-labelledby'> {
  placement?: DrawerPosition;
  onDismiss?: R.DialogProps['hide'];
  initialFocusRef?: R.DialogProps['unstable_initialFocusRef'];
  'aria-labelledby'?: string | typeof DRAWER_HEADER_ID;
}

export function Drawer({
  onDismiss = noop,
  initialFocusRef,
  children,
  placement = 'right',
  'aria-labelledby': ariaLabelledBy,
  ...dialog
}: DrawerProps) {
  const hide = () => {
    dialog && dialog.hide?.();
    onDismiss();
  };
  const BackdropWrapper = dialog.modal ? Backdrop : React.Fragment;
  const dialogState = { ...dialog, hide };
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const scrollBox = ref.current!;
    if (dialog.modal && dialog.visible) {
      disableBodyScroll(scrollBox);
    }
    return () => enableBodyScroll(scrollBox);
  }, [dialog.modal, dialog.visible]);

  return (
    <DrawerContext.Provider value={{ dialog: dialogState, placement }}>
      <BackdropWrapper {...(dialog.modal ? dialog : {})}>
        <R.Dialog
          ref={ref}
          {...dialogState}
          data-drawer="true"
          data-placement={placement}
          unstable_initialFocusRef={initialFocusRef}
          aria-labelledby={
            ariaLabelledBy === DRAWER_HEADER_ID
              ? getHeaderId(dialog)
              : ariaLabelledBy
          }
        >
          {(dialogProps) => (
            <S.Dialog as="section" {...dialogProps} placement={placement}>
              {children}
            </S.Dialog>
          )}
        </R.Dialog>
      </BackdropWrapper>
    </DrawerContext.Provider>
  );
}

export interface DrawerCloseButtonProps {
  'aria-label'?: string;
  title?: string;
}

export function DrawerCloseButton({
  'aria-label': ariaLabel = 'Close modal',
  title,
}: DrawerCloseButtonProps) {
  const { dialog } = useContext(DrawerContext);
  return (
    <CloseButton aria-label={ariaLabel} title={title} onClick={dialog.hide} />
  );
}

export interface DrawerHeaderProps {
  children: ReactNode;
  action?: ReactNode;
  ariaCloseButtonLabel?: string;
  id?: string;
}

export function DrawerHeader({
  children,
  action,
  ariaCloseButtonLabel = 'Close drawer',
  id,
}: DrawerHeaderProps) {
  const { placement, dialog } = useContext(DrawerContext);
  if (placement === 'left') {
    return (
      <S.Header aria-labelledby={id ?? getHeaderId(dialog)}>
        <S.Action justifyContent="flex-end">{action}</S.Action>
        <S.Text id={id ?? getHeaderId(dialog)}>{children}</S.Text>
        <S.Action>
          <DrawerCloseButton aria-label={ariaCloseButtonLabel} />
        </S.Action>
      </S.Header>
    );
  }
  return (
    <S.Header aria-labelledby={id ?? getHeaderId(dialog)}>
      <S.Action>
        <DrawerCloseButton />
      </S.Action>
      <S.Text id={id ?? getHeaderId(dialog)}>{children}</S.Text>
      <S.Action justifyContent="flex-end">{action}</S.Action>
    </S.Header>
  );
}

/**
 * @implements Box
 */
export const DrawerContent = S.Content as typeof S.Content;

/**
 * @implements Box
 */
export const DrawerActions = S.Actions; // as typeof Stack;

export interface DrawerDisclosureProps extends R.DialogDisclosureProps {}
/**
 * Accessible Disclosure component that controls visibility of a section of content (Drawer)
 */
export const DrawerDisclosure = R.DialogDisclosure;
