import formatNumber from './formatNumber';

/**
 * Formats a number to a string, but truncates the number to include a cardinal
 * when over a certain threshold. Billions will be printed as `X mrd.` and
 * millions will be printed as `X mill.`.
 *
 * Note that the number and the cardinal are separated with a non-breaking
 * space.
 *
 * @param {number|string} number  - The number to be formatted
 * @param {number|object} options - Options passed to `Intl.NumberFormat`.
 * Alternatively the amount of decimals to use
 * @param {string} locale - The locale to use in formatting. Default uses
 * the navigator's language
 * @return {string} The number formatted according to the nb locale.
 */
export default (
  num: number,
  options: Intl.NumberFormatOptions | number = {},
  locale: string = navigator.language,
) => {
  if (num >= 1000000000) {
    return `${formatNumber(num / 1000000000, options, locale)} mrd.`;
  }

  if (num >= 1000000) {
    return `${formatNumber(num / 1000000, options, locale)} mill.`;
  }

  return formatNumber(num, options, locale);
};
