import { useState, useRef, useEffect } from 'react';
import Immutable from 'immutable';
import Jaws from '@oms/jaws';
import { useJawsContext } from './context';
import JawsEngine, { ImmutableEngineState } from './JawsEngine';

const rand = (amount = 4) =>
  Math.random()
    .toString(36)
    .slice(2, amount + 2);
const generateUUID = () =>
  `${rand(8)}-${rand()}-${rand()}-${rand()}-${rand(8)}`;

/** The spec the component should subscribe to. Continuously watched for changes */
export type Spec = {
  [key: string]: any;
};

export type Options = {
  /** If `true`, forces the component to use HTTP */
  forceHTTPConnection?: boolean;
  /** If `true`, then no subscription will happen on mount, even if a spec was given when connecting */
  disableAutoSubscribe?: boolean;
};

export type Resource = {
  _resolved: boolean;
  _resolve?: () => void;
  _reject?: () => void;
  promise?: Promise<void>;
};

export interface JawsApi {
  /** A reference to the Jaws instance (see @oms/jaws) */
  jaws: typeof Jaws;
  /** Has the component completed fetching and has empty data? */
  emptyData: boolean;
  /** Is the component currently fetching data? */
  jawsFetching: boolean;
  /** Has the component completed fetching and has non-empty data? */
  hasData: boolean;
  /** An object with a promise that resolves when initialized */
  resource?: Resource;
  /** Has the component received `initial_data_sent?` */
  initialized: boolean;
  /** The subscribed dataset */
  items: Immutable.Map<any, any>;
  /** The total size of the response as provided by jaws */
  totalSize: number;
}

const useJaws = (
  spec?: Spec,
  { forceHTTPConnection, disableAutoSubscribe }: Options = {},
): JawsApi => {
  const stringifiedSpec = JSON.stringify(spec);
  const { instance } = useJawsContext();
  const [state, setState] = useState({
    initialized: false,
    items: Immutable.Map(),
    totalSize: 0,
  });
  const jaws = forceHTTPConnection
    ? new Jaws({ url: instance.url, useWebSockets: false })
    : instance;

  /*
   * This ref is not initialized in the initial render because of how hooks work
   * with suspense. If a component suspends during initial render, which would
   * intuitively make sense for a component that requires data for rendering,
   * registered side effects like `useEffect` do not run. Thus it is impossible
   * to throw a promise during initial render, then run an effect to set up a
   * subscription, resolving the promise when initial data is recieved.
   *
   * The solution for now is that the component must complete rendering before
   * we can initialize the promise and throw it in the render method,
   * triggering suspense. This is why the resource is initialized to undefined.
   */
  const resource = useRef<Resource>();

  function handleUpdate(engineState: ImmutableEngineState) {
    if (!resource.current) return;

    // TODO is this initialized true after init frame?
    if (!resource.current._resolved && engineState.get('initialized')) {
      resource.current._resolve?.();
      resource.current._resolved = true;
    }

    // Engine state contains initialized, items and totalSize
    setState({
      initialized: engineState.get('initialized'),
      items: engineState.get('items'),
      totalSize: engineState.get('totalSize'),
    });
  }

  function resetResource() {
    // Resolve existing promise in case someone is listening to that.
    // This may avoid infinite loading states with frequent spec changes
    resource.current?._resolve?.();

    resource.current = {
      _resolved: false,
    };
    resource.current.promise = new Promise((resolve, reject) => {
      if (resource.current) {
        resource.current._resolve = resolve;
        resource.current._reject = reject;
      }
    });
  }

  useEffect(() => {
    resetResource();

    const subscriptionId = generateUUID();
    const engine = new JawsEngine();
    engine.onUpdate(handleUpdate);

    if (spec && !disableAutoSubscribe) {
      const outboundSpec = {
        ...spec,
        channel: subscriptionId,
      };

      const sub = jaws.subscribe(subscriptionId, outboundSpec);
      sub.bind('new', engine.handleNewEvent);
      sub.bind('update', engine.handleUpdateEvent);
      sub.bind('delete', engine.handleDeleteEvent);
      sub.bind('channel_token_received', engine.handleChannelToken);
      sub.bind('meta', engine.handleMetaEvent);
    }

    return () => {
      engine.reset();

      if (subscriptionId) {
        jaws.unsubscribe(subscriptionId);
      }
    };
  }, [stringifiedSpec, jaws.useWebSockets]);

  return {
    jaws,
    emptyData: !state.items.size && state.initialized,
    jawsFetching: !state.items.size && !state.initialized,
    hasData: !!state.items.size,
    resource: resource.current,
    ...state,
  };
};

interface Props extends Partial<Options> {
  children: (result: JawsApi) => any;
  spec: Spec;
}

const JawsComponent = ({ children, spec, ...props }: Props) => {
  const jawsData = useJaws(spec, props);
  return children(jawsData);
};

JawsComponent.displayName = 'JawsComponent';

export { JawsComponent, useJaws };
