import React from 'react';
import styled from '@xstyled/emotion';
import { th } from '@xstyled/system';
import { Text } from '@oms/ui-text';
import { Wrap } from '@oms/ui-wrap';
import { Box } from '@oms/ui-box';
import { AppLogo } from './AppLogo';

export const FooterContainer = styled.footer`
  background-color: #424242;
  color: inverse-text;
  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-top: 3;
  }

  address {
    font-style: inherit;
  }

  a {
    text-decoration: none;
    color: inverse-text;
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const FooterHeader = styled.h2`
  ${th('heading.variants.heading5')}
  text-transform:uppercase;
  color: #fff;
  margin-bottom: 4;
`;

export const FooterSection = ({
  title,
  children,
}: {
  title?: string;
  children?: React.ReactNode;
}) => (
  <Box>
    {title && <FooterHeader>{title}</FooterHeader>}
    {children}
  </Box>
);

export const AppFooterLogo = () => (
  <FooterSection>
    <AppLogo onDarkBg={true} height="7rem" />
  </FooterSection>
);

export const AppFooterGroupWebsites = () => (
  <FooterSection title="Group websites">
    <ul>
      <li>
        <a href="https://www.infrontfinance.com/">Infront</a>
      </li>
    </ul>
  </FooterSection>
);

export const AppFooterEnxWebsites = () => (
  <FooterSection title="Group websites">
    <ul>
      <li>
        <a href="https://www.infrontfinance.com//">Infront</a>
      </li>
      <li>
        <a href="https://www.vps.no/pub/">VPS</a>
      </li>
      <li>
        <a href="http://fishpool.eu/">Fish Pool</a>
      </li>
      <li>
        <a href="https://newsweb.oslobors.no/">Newsweb</a>
      </li>
    </ul>
  </FooterSection>
);

export const AppFooterOMSContact = ({
  children = (
    <ul>
      <li>INFRONT</li>
      <li>
        <address>Munkedamsveien 45, 0250 Oslo, Norway</address>
      </li>
      <li>
        <address>Kungsgatan 33, 111 56 Stockholm, Sweden</address>
      </li>
      <li>
        <a href="tel:+4740002313">+47 40 00 23 13</a>
      </li>
      <li>
        <a href="mailto:">supportirhs@infrontfinance.com</a>
      </li>
    </ul>
  ),
}: {
  children?: React.ReactNode;
}) => <FooterSection title="contact">{children}</FooterSection>;

export interface AppFooterProps {
  copyrightNotice?: React.ReactElement | string | null | undefined;
  children?: React.ReactNode;
}

export const AppFooter = ({
  children = [
    <AppFooterLogo />,
    <AppFooterGroupWebsites />,
    <AppFooterOMSContact />,
  ],
  copyrightNotice = (
    <>
      <a href="https://www.infrontfinance.com/"> INFRONT.</a> All Rights
      Reserved.
    </>
  ),
}: AppFooterProps) => {
  return (
    <FooterContainer>
      <Wrap gap={6} width="100%" py={6} px={4}>
        {React.Children.map(children, (child) => (
          <Box as="section" flex="1 0 15rem">
            {child}
          </Box>
        ))}
      </Wrap>
      <Text
        variant="body1"
        color="inverse-text"
        mx="auto"
        textAlign="center"
        m={4}
      >
        © {new Date(Date.now()).getFullYear()} {copyrightNotice}
      </Text>
    </FooterContainer>
  );
};
