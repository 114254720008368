import React, { cloneElement, useMemo, ReactNode, ReactElement } from 'react';
import { warning, forwardRefWithAs } from '@oms/ui-utils';
import { useRadioGroup } from '@oms/ui-radio';
import { Tooltip } from '@oms/ui-tooltip';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import * as S from './styles';

export interface SegmentProps {
  children?: ReactNode;
  id?: string;
  label?: string;
  value: string | number;
  checked?: boolean;
  message?: string;
  messageId?: string;
  errorId?: string;
  content?: ReactNode;
}

export const Segment = forwardRefWithAs<SegmentProps, 'input'>(function Segment(
  {
    id,
    content,
    value,
    label,
    checked,
    message,
    messageId,
    /** We don't need these props */
    as: _as,
    errorId: _errorId,
    'aria-describedby': _ariaDescribedBy,
    ...props
  },
  ref,
) {
  const name = useRadioGroup();
  warning(
    !name,
    '[Segment]',
    'you can only use a Segment inside a SegmentGroup',
  );
  const clone = useMemo(() => {
    switch (typeof content) {
      case 'undefined':
        return label || value;
      case 'function':
        return content({ checked });
      case 'number':
      case 'string':
        return content;
      default:
        return (
          <>
            {cloneElement(content as ReactElement<any>, { checked })}
            <VisuallyHidden>{label}</VisuallyHidden>
          </>
        );
    }
  }, [content, checked]);

  if (message) {
    return (
      <S.Wrapper>
        <Tooltip content={message} placement="top">
          <S.Input
            ref={ref}
            as="input"
            type="radio"
            id={id}
            name={name}
            value={value}
            checked={checked}
            aria-checked={checked}
            {...props}
          />
        </Tooltip>
        <S.Label htmlFor={id}>{clone}</S.Label>
      </S.Wrapper>
    );
  }
  return (
    <S.Wrapper>
      <S.Input
        ref={ref}
        as="input"
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        aria-checked={checked}
        {...props}
      />
      <S.Label htmlFor={id}>{clone}</S.Label>
    </S.Wrapper>
  );
});
Segment.displayName = 'Segment';
