import { useRef, useEffect } from 'react';

/**
 * Returns the previous value of a reference after a component update.
 *
 * @param value
 * @see https://github.com/reach/reach-ui/blob/master/packages/utils/src/index.tsx
 */
export function usePrevious<ValueType = any>(value: ValueType) {
  const ref = useRef<ValueType | null>(null);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
