import React, { createContext, useContext, ReactNode } from 'react';
import { Stack, StackProps } from '@oms/ui-stack';
import {
  Label,
  Message,
  Error,
  ErrorIcon,
  getErrorId,
  getMessageId,
} from '@oms/ui-field';
import { ReactNodeNoStrings, Size } from '@oms/ui-utils';

export const RadioGroupContext = createContext<string>('');
RadioGroupContext.displayName = 'RadioGroupContext';
export const useRadioGroup = (): string => useContext(RadioGroupContext);

type StackWithoutSize = Omit<StackProps, 'size'>;
export interface RadioGroupProps extends StackWithoutSize {
  name: string;
  /** ReactNode allows for label={<VisuallyHidden>Label</VisuallyHidden>} */
  legend?: string;
  children: ReactNodeNoStrings;
  message?: ReactNode;
  error?: string;
  size?: Omit<Size, 'sm'>;
}
export function RadioGroup({
  name,
  children,
  legend,
  message,
  error,
  orientation = 'vertical',
  gap = orientation === 'vertical' ? 2 : 3,
}: RadioGroupProps) {
  const errorId = getErrorId(name),
    messageId = getMessageId(name);
  return (
    <RadioGroupContext.Provider value={name}>
      <Stack gap={1} as="fieldset" aria-describedby={messageId}>
        <Label as="legend">{legend}</Label>
        <Message id={messageId}>{message}</Message>
        <Stack orientation={orientation} gap={gap}>
          {children}
        </Stack>
        <Error id={errorId} data-hidden={!error}>
          <ErrorIcon />
          {error}
        </Error>
      </Stack>
    </RadioGroupContext.Provider>
  );
}
RadioGroup.displayName = 'RadioGroup';
