import React from 'react';
import { useId } from '@oms/ui-utils';
import { useLink } from '@oms/ui-components-context';
import styled from '@xstyled/emotion';
import { Text } from '@oms/ui-text';
import { Stack } from '@oms/ui-stack';
import { Separator } from '@oms/ui-separator';

const Container = styled.div`
  margin: 0 -4;
  box-sizing: border-box;
  background-color: transparent;
  &:not(:first-of-type) {
    margin-top: 3;
  }
`;

const ListItem = styled.li`
  display: flex;
`;

const Link = styled.a`
  padding: 4;
  min-height: 3rem;
  font-size: md;
  width: 100%;
  text-decoration: none;
  color: inverse-text;
  &:focus,
  &:hover {
    text-decoration: underline;
    background-color: rgba(248, 250, 251, 0.08);
  }
`;

type NavLink = {
  path: string;
  label: React.ReactElement | string;
};

export interface AppNavigationDrawerSectionProps {
  title: React.ReactElement | string;
  routes: NavLink[];
  children?: React.ReactNode;
}

export function AppNavigationDrawerSection({
  title,
  routes = [],
  children,
}: AppNavigationDrawerSectionProps) {
  const LinkImpl = useLink();
  const id = useId();
  return (
    <Container>
      <Text
        id={id}
        variant="heading5"
        as="div"
        p={4}
        pb={4}
        color="inverse-text"
      >
        {title}
      </Text>
      <Stack as="ul" aria-labelledby={id} gap={0}>
        {routes?.map((route) => {
          return (
            <ListItem key={route.path + route.label}>
              <Link as={LinkImpl} to={route.path}>
                {route.label}
              </Link>
            </ListItem>
          );
        })}
      </Stack>
      {children}
      <Separator borderColor={'rgba(248, 250, 251, 0.08)' as any} />
    </Container>
  );
}
