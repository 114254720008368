import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, useTheme, css, Global } from '@xstyled/emotion';
import notes, { Position } from 'toasted-notes';

const toastStyles = css`
  .Toaster__message-wrapper {
    padding: 1rem;
    text-align: left;
  }
`;

interface RenderArgs {
  id: string;
  onClose: () => void;
  as?: React.ElementType;
  maxWidth?: string;
}

// ReactNode
type RenderToast = (options: RenderArgs) => React.ReactNode;

type ToastOptions = {
  position?: keyof typeof Position;
  duration?: number | null;
};

export function useToast() {
  const theme = useTheme();
  return function toast(render: RenderToast, options?: ToastOptions) {
    return notes.notify(
      ({ onClose, id }) => (
        <>
          <Global styles={toastStyles} />
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              {render({ onClose, id, as: 'div', maxWidth: '35rem' })}
            </BrowserRouter>
          </ThemeProvider>
        </>
      ),
      options,
    );
  };
}
