import React, { ReactNode } from 'react';
import { forwardRefWithAs } from '@oms/ui-utils';
import { StatusIcon, StatusIntentions } from '@oms/ui-icon';
import { Box, BoxProps } from '@oms/ui-box';
import { Text } from '@oms/ui-text';
import * as S from './styles';

export type NotificationProps = BoxProps & {
  /** Place in the application the notification originates from. E.g. Trader */
  sender?: string;
  title: React.ReactElement | string;
  /**
   * Changes icon and styles of the notification
   */
  status?: StatusIntentions;
  children: ReactNode;
  id?: string;
  /**
   * If used, a close button will be added to the notification
   */
  onClose?: () => void | undefined;
  type?: 'polite' | 'assertive';
  ariaCloseButtonLabel?: string;
};

export const Notification = forwardRefWithAs<NotificationProps, 'div'>(
  function Notification(
    {
      children,
      title,
      status = 'info',
      onClose,
      type = 'polite',
      ariaCloseButtonLabel = 'Dismiss notification',
      ...props
    },
    ref,
  ) {
    return (
      <S.Container
        ref={ref}
        {...props}
        type={type}
        data-status={status}
        role="status"
        aria-live={type}
      >
        <Box display="flex">
          <StatusIcon status={status} />
          <Box flex={1} ml={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Text variant="heading6">{title}</Text>
              {onClose && (
                <S.CloseButton
                  onClick={onClose}
                  aria-label={ariaCloseButtonLabel}
                  size="sm"
                />
              )}
            </Box>
            <Text mt={2}>{children}</Text>
          </Box>
        </Box>
      </S.Container>
    );
  },
);
