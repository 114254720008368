import React from 'react';
import { ThBox, SortButton } from './styles';
import { ThProps, ThSortableProps } from './types';
import { getAriaSortValue, getAriaLabelValue } from './utils';

const getShouldAlignRight = (type: ThProps['type']) =>
  ['percent', 'number', 'numberWithUnit', 'integer'].includes(type ?? '');

export function Th({ children, type, ...props }: ThProps) {
  return (
    <ThBox
      textAlign={getShouldAlignRight(type) ? 'right' : undefined}
      {...props}
    >
      {children}
    </ThBox>
  );
}

/**
 * SortIcons based on:
 * @link https://fontawesome.com/icons/sort-alt?style=light
 * @link https://fontawesome.com/icons/long-arrow-up?style=light
 * @link https://fontawesome.com/icons/long-arrow-down?style=light
 */
export function ThSortable({
  children,
  onClick,
  isSorted,
  isSortedDesc,
  type,
  flexDirection, // = 'row-reverse',
  ...props
}: ThSortableProps) {
  const inner = isSorted ? (
    isSortedDesc ? (
      <path
        fill="currentColor"
        d="M252.485 343.03l-7.07-7.071c-4.686-4.686-12.284-4.686-16.971 0L145 419.887V44c0-6.627-5.373-12-12-12h-10c-6.627 0-12 5.373-12 12v375.887l-83.444-83.928c-4.686-4.686-12.284-4.686-16.971 0l-7.07 7.071c-4.686 4.686-4.686 12.284 0 16.97l116 116.485c4.686 4.686 12.284 4.686 16.971 0l116-116.485c4.686-4.686 4.686-12.284-.001-16.97z"
      />
    ) : (
      <path
        fill="currentColor"
        d="M3.515 168.97l7.07 7.071c4.686 4.686 12.284 4.686 16.971 0L111 92.113V468c0 6.627 5.373 12 12 12h10c6.627 0 12-5.373 12-12V92.113l83.444 83.928c4.686 4.686 12.284 4.686 16.971 0l7.07-7.071c4.686-4.686 4.686-12.284 0-16.97l-116-116.485c-4.686-4.686-12.284-4.686-16.971 0L3.515 152c-4.687 4.686-4.687 12.284 0 16.97z"
      />
    )
  ) : (
    <path
      fill="currentColor"
      d="M364.5 117.81l-84.06-82.3a11.94 11.94 0 00-16.87 0l-84 82.32a12 12 0 00-.09 17l5.61 5.68a11.93 11.93 0 0016.91.09l54-52.67V472a8 8 0 008 8h16a8 8 0 008-8V88.08l53.94 52.35a12 12 0 0016.92 0l5.64-5.66a12 12 0 000-16.96zM198.93 371.56a11.93 11.93 0 00-16.91-.09l-54 52.67V40a8 8 0 00-8-8H104a8 8 0 00-8 8v383.92l-53.94-52.35a12 12 0 00-16.92 0l-5.64 5.66a12 12 0 000 17l84.06 82.3a11.94 11.94 0 0016.87 0l84-82.32a12 12 0 00.09-17z"
    />
  );

  const isNumeric = getShouldAlignRight(type);

  return (
    <Th {...props} aria-sort={getAriaSortValue(isSorted, isSortedDesc)}>
      <SortButton
        data-sorted={isSorted}
        onClick={onClick}
        justifyContent={
          isNumeric
            ? flexDirection === 'row-reverse'
              ? 'flex-start'
              : 'flex-end'
            : flexDirection === 'row-reverse'
            ? 'flex-end'
            : 'flex-start'
        }
        flexDirection={flexDirection}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          aria-label={getAriaLabelValue(isSorted, isSortedDesc)}
        >
          {inner}
        </svg>
        <span>{children}</span>
      </SortButton>
    </Th>
  );
}
