const DEFAULT_DIGITS = 2;

const isNumberString = /[0-9,.]+/;
const isNumeric = (input: any) => {
  if (typeof input === 'number') return true;
  if (typeof input !== 'string') return false;
  return isNumberString.test(input);
};

function isNumber(x: any): x is number {
  return typeof x === 'number';
}

/**
 * Formats a number into a string with a given number format.
 * This is to display the number in a familiar manner to the
 * user.
 *
 * If the `Intl` API is not supported, the number will not
 * be left untouched and not formatted.
 *
 * @param {number|string} number  - The number to be formatted
 * @param {number|object} options - Options passed to `Intl.NumberFormat`.
 * Alternatively the amount of decimals to use
 * @param {string} locale - The locale to use in formatting. Default uses
 * the navigator's language
 * @return {string} The number formatted according to the provided locale.
 */
export default function formatNumber(
  num: string | number,
  options: Intl.NumberFormatOptions | number = {},
  locale: string = navigator.language,
): string {
  if (!num || !isNumeric(num)) return '-';
  if (!Intl) return `${num}`;

  let newOptions = {};

  if (isNumber(options)) {
    newOptions = {
      minimumFractionDigits: options ?? DEFAULT_DIGITS,
      maximumFractionDigits: options ?? DEFAULT_DIGITS,
    };
  } else {
    newOptions = {
      ...options,
      minimumFractionDigits: options.minimumFractionDigits ?? DEFAULT_DIGITS,
      maximumFractionDigits: options.maximumFractionDigits ?? DEFAULT_DIGITS,
    };
  }

  return Intl.NumberFormat(locale, newOptions).format(Number(num));
}
