import React from 'react';
import styled from '@xstyled/emotion';
import { forwardRefWithAs, SystemProps, mixins } from '@oms/ui-utils';

const StyledBox = styled.box`
  ${mixins.borderBox}
  ${mixins.borderWidth}
`;

export interface BoxProps extends SystemProps {
  center?: boolean;
}

/**
 * The `Box` component is your "do it all component".
 * By default it's a div, but using the `as` prop it can be used for anything.
 * The `Box` component has all `system` props built-in.
 *
 * Being able to style your components directly from props in a consistent way is very powerful.
 * Use the `Box`-component and style props when styles are dependent on state, you need to quickly override for example a background color or use them for **layout, flex, grid** and above all **space** props.
 * @since 1.0.0
 */
export const Box = forwardRefWithAs<BoxProps, 'div'>(function Box(
  { center, ...props },
  ref,
) {
  const centerProps = center
    ? {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    : {};
  return <StyledBox ref={ref} {...centerProps} {...props} />;
});
