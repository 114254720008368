import React, { HTMLProps } from 'react';
import { css } from '@emotion/core';

const unstyledButton = css`
  background-color: rgba(0, 0, 0, 0);
  text-align: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  border: 0;
`;

export const UnstyledButton = ({
  children,
  className = 'OmsUnstyledButton',
  type = 'button',
  ...props
}: HTMLProps<HTMLButtonElement>) => {
  return (
    <button
      type={type as JSX.IntrinsicElements['button']['type']}
      css={unstyledButton}
      {...props}
    >
      {children}
    </button>
  );
};

export default UnstyledButton;
