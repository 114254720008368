import React from 'react';
import { Menu, MenuItem } from '@oms/ui-menu';
import { light } from '@oms/ui-icon';
import { IconButton } from '@oms/ui-icon-button';

export interface AppUserMenuProps {
  /** Menu items */
  children: React.ReactNode;
  /** Used to render the avatar when logged in */
  username?: string;
}

export const AppUserMenuItem = MenuItem;

export function AppUserMenu({ children }: AppUserMenuProps) {
  return (
    <Menu disclosure={<IconButton icon={light.faUser} aria-label="user" />}>
      {children}
    </Menu>
  );
}
