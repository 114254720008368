import React from 'react';
import {
  forwardRefWithAs,
  mapProps,
  noop,
  Size,
  SystemProps,
} from '@oms/ui-utils';
import { Icon, IconProps, Spinner } from '@oms/ui-icon';
import { Tooltip, TooltipProps } from '@oms/ui-tooltip';
import * as S from './styles';

type Icon = IconProps['icon'];
type Tooltip = TooltipProps['content'];
type Variant = 'primary' | 'secondary';
export interface IconButtonProps extends SystemProps {
  icon: Icon;
  size?: Size;
  tooltip?: Tooltip;
  variant?: Variant;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | ((event: React.MouseEvent<'button', MouseEvent>) => void)
    | undefined;
  /** State to indicate a process is busy.
   * @example ```tsx
   * <IconButton icon={light.faTimes} isPending={updateIsPending} />
   * ```
   */
  isPending?: boolean;
}

const getSize = mapProps({
  sm: 'sm',
  md: undefined,
  lg: 'lg',
});

export const IconButton = forwardRefWithAs<IconButtonProps, 'button'>(
  function IconButton(
    {
      icon,
      size = 'md',
      tooltip,
      onClick = noop,
      title,
      'aria-label': ariaLabel,
      isPending,
      disabled = isPending,
      ...buttonProps
    },
    ref,
  ) {
    const i = isPending ? (
      <Spinner size={getSize(size) as IconProps['size']} />
    ) : (
      <Icon icon={icon} size={getSize(size) as IconProps['size']} />
    );

    if (tooltip) {
      // Ref goes on the tooltip, tooltip forwards the ref to the button
      return (
        <Tooltip content={tooltip}>
          <S.Button
            ref={ref}
            size={getSize(size)}
            onClick={disabled ? noop : onClick}
            aria-disabled={disabled}
            title={disabled ? `${title} disabled` : title}
            aria-label={disabled ? `${ariaLabel} disabled` : ariaLabel}
            type="button"
            {...buttonProps}
          >
            {i}
          </S.Button>
        </Tooltip>
      );
    }
    // Ref goes on the button
    return (
      <S.Button
        ref={ref}
        size={getSize(size)}
        onClick={disabled ? noop : onClick}
        aria-disabled={disabled}
        title={disabled ? `${title} disabled` : title}
        aria-label={disabled ? `${ariaLabel} disabled` : ariaLabel}
        type="button"
        {...buttonProps}
      >
        {i}
      </S.Button>
    );
  },
);
