import React, { ReactNode, useEffect } from 'react';
import * as P from 'react-reverse-portal';
import { generateId } from '@oms/ui-utils';

export interface TeleporterSourceProps {
  children: ReactNode;
}

type HTMLAttributes = React.HTMLAttributes<HTMLElement>;

export type TeleporterCreateOptions = HTMLAttributes & {
  id?: HTMLAttributes['id'];
  role?: HTMLAttributes['role'];
  className?: HTMLAttributes['className'];
};

/**
 * Render an element once, move it anywhere. Similar React portals, but preserves
 * state in components. Useful for forms, etc.
 *
 * NOTE: createTeleporter will wrap your component with an additional div-element.
 * You can pass an id to the element like this: createTeleporter('your-id').
 * The default id starts with "teleporter". In CSS you can target the element like: [id*="teleporter"] {}
 * ```ts
 * const TabBar = createTeleporter()
 *
 * const mutatateMe = TabBar.ref
 *
 * return (
 *  <>
 *    <TabBar.Source><Tabs /></TabBar.Source>
 *    <TabBar.Target/> // ← Tabs renders here
 *  </>
 * )
 * ```
 */
export function createTeleporter(
  config: TeleporterCreateOptions = { id: generateId('teleporter') },
) {
  const node = P.createHtmlPortalNode();

  Object.entries(config).forEach(([attr, value]) => {
    node.element.setAttribute(attr, value);
  });

  return {
    Source: ({ children }: TeleporterSourceProps) => {
      return <P.InPortal node={node}>{children}</P.InPortal>;
    },
    Target: ({ portalAttributes, ...props }: any) => {
      usePortalAttributes(node.element, portalAttributes);
      return <P.OutPortal node={node} {...props} />;
    },
    ref: node.element,
  };
}

export function usePortalAttributes(
  element: any,
  attributes: React.HTMLAttributes<HTMLElement>,
) {
  useEffect(() => {
    if (element && attributes) {
      Object.entries(attributes).forEach(([attr, value]) => {
        if (value) {
          element.setAttribute(attr, value);
        }
      });
    }
  }, [element, attributes]);
}
