import { FC, memo } from 'react';
import styled, { css } from '@xstyled/emotion';
import { th, variant, system } from '@xstyled/system';
import { createSystemComponent, mixins } from '@oms/ui-utils';
import {
  TableProps,
  TableElementProps,
  TableToggleRowsButtonProps,
} from './types';

const layout = (props: any) =>
  props?.layout === 'auto'
    ? css`
        table-layout: auto;
      `
    : css`
        table-layout: fixed;
      `;

const stickyHeader = (props: any) =>
  props.stickyHeader
    ? css`
        thead th {
          position: sticky;
          top: 0;
          ${th('table.stickyHeader')(props)}
        }
      `
    : undefined;

export const TableScroll = styled.box`
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  background:
            /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  background-position: 0 0, 100%, 0 0, 100%;
  background-attachment: local, local, scroll, scroll;
`;

export const StyledTable: FC<TableProps> = styled(
  createSystemComponent('table', [
    'variant',
    'density',
    'layout',
    'stickyHeader',
  ]),
)`
  ${mixins.borderBox}
  ${mixins.table}
  width: 100%;
  border-collapse: collapse;
  color: text;
  text-align: left;
  font-variant-numeric: tabular-nums;
  font-variant-numeric: tnum;
  vertical-align: middle;
  font-size: md;
  tr {
    transition: background-color 200ms ease;
  }
  tbody tr th {
    text-align: left !important;
  }
  ${system}
  ${layout}
  /* Default theme styles to apply to all tables */
  ${th(
    'table.default',
  )}
  /* Layout theme styles to apply to all tables */
  ${variant({
    key: 'table.variants',
    default: 'normal',
  })}
  /* Layout styles to apply to all tables */
  ${variant({
    default: 'normal',
    variants: {
      normal: css``,
      keyValue: css`
        tbody tr th ~ td {
          text-align: right;
        }
      `,
      flipped: css`
        display: flex;

        tbody {
          display: flex;
          position: relative;
          overflow-x: auto;
          overflow-y: hidden;
          max-width: 100%;
        }

        tr {
          height: auto !important;
        }

        th {
          text-align: left !important;
        }

        td {
          text-align: right !important;
        }

        th,
        td {
          white-space: nowrap;
          display: block;
          min-width: initial;
        }
        [data-name] {
          position: relative;
        }
      `,
    },
  })}
  /* Row/Cell (height) styles to apply to all tables */
  ${variant({
    prop: 'density',
    key: 'table.densities',
    default: 'normal',
  })}
  ${stickyHeader}
`;
StyledTable.defaultProps = {
  role: 'table',
};
export const Table = memo(StyledTable);

export const Thead: FC<TableElementProps> = styled.theadBox``;
Thead.defaultProps = {
  role: 'rowgroup',
};

export const Caption: FC<TableElementProps> = styled.captionBox``;

export const Tbody: FC<
  TableElementProps & { as?: 'div' | 'ul' }
> = styled.tbodyBox``;
Tbody.defaultProps = {
  role: 'rowgroup',
};

export const Tfoot: FC<
  TableElementProps & { as?: 'div' | 'ul' }
> = styled.tfootBox``;
Tfoot.defaultProps = {
  role: 'rowgroup',
};

export const Tr: FC<TableElementProps & { as?: 'div' | 'li' }> = styled.trBox`
  &[aria-selected='true'],
  &[aria-checked='true'],
  &[data-selected] {
    ${th('table.rowSelected')}
  }
`;
Tr.defaultProps = {
  role: 'row',
};

export const ThBox = styled.thBox`
  position: relative;
  &[data-type='integer'],
  &[data-type='number'],
  &[data-type='numberWithUnit'],
  &[data-type='percent'] {
    white-space: nowrap;
    text-align: right;
  }
  &[data-type='date'],
  &[data-type='time'],
  &[data-type='dateTime'],
  &[data-type='ban'],
  &[data-type='nin'],
  &[data-type='text'] {
    text-align: left;
  }
`;
ThBox.defaultProps = {
  role: 'columnheader',
};

export const TdBox = styled.tdBox`
  text-align: left;
  // position: relative;

  &[data-truncate] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &[data-prefix]::before {
    content: attr(data-prefix);
  }
  &[data-suffix]::after {
    content: attr(data-suffix);
  }
  &[data-type='integer'],
  &[data-type='number'],
  &[data-type='numberWithUnit'],
  &[data-type='percent'] {
    white-space: nowrap;
  }
  &[data-type='date'],
  &[data-type='time'],
  &[data-type='ban'],
  &[data-type='nin'],
  &[data-type='text'] {
  }

  /* trend */
  &[data-trend='pos'] {
    ${th('table.cells.trend.pos')}
  }
  &[data-trend='neg'] {
    ${th('table.cells.trend.neg')}
  }

  /* flash on update */
  &[data-flash] {
    animation-duration: 400ms;
    animation-timing-function: ease-in-out;
    will-change: color, background-color;
    transform: translate(0, 0);
    /** 
      We use animations in favor of transitions as it allows us
      to reduce the amount of DOM updates by 50% per cell per tick.

      This complicates the setup a bit, but it provides a significant performance boost
      in the Quotelist during high traffic hours.

      Here's the trick:
      Animations don't run if you want to run the same animation twice  in a row.
      We toggle between animation and animation-2, hence the duplication of css.

      No worries though, in the theme config all is normalized.
     */

    @keyframes pos {
      0% {
        background-color: inherit;
        color: inherit;
      }
      80% {
        ${th('table.cells.flash.pos')}
      }
      100% {
        background-color: inherit;
        color: inherit;
      }
    }

    @keyframes pos-2 {
      0% {
        background-color: inherit;
        color: inherit;
      }
      80% {
        ${th('table.cells.flash.pos')}
      }
      100% {
        background-color: inherit;
        color: inherit;
      }
    }

    @keyframes neg {
      0% {
        background-color: inherit;
        color: inherit;
      }
      80% {
        ${th('table.cells.flash.neg')}
      }
      100% {
        background-color: inherit;
        color: inherit;
      }
    }
    @keyframes neg-2 {
      0% {
        background-color: inherit;
        color: inherit;
      }
      80% {
        ${th('table.cells.flash.neg')}
      }
      100% {
        background-color: inherit;
        color: inherit;
      }
    }

    @keyframes flat {
      0% {
        background-color: inherit;
        color: inherit;
      }
      80% {
        ${th('table.cells.flash.flat')}
      }
      100% {
        background-color: inherit;
        color: inherit;
      }
    }
    @keyframes flat-2 {
      0% {
        background-color: inherit;
        color: inherit;
      }
      80% {
        ${th('table.cells.flash.flat')}
      }
      100% {
        background-color: inherit;
        color: inherit;
      }
    }
  }

  &[data-flash='neg'] {
    animation-name: neg;
  }
  &[data-flash='neg-2'] {
    animation-name: neg-2;
  }

  &[data-flash='pos'] {
    animation-name: pos-2;
  }
  &[data-flash='pos-2'] {
    animation-name: pos;
  }

  &[data-flash='flat'] {
    animation-name: flat;
  }
  &[data-flash='flat-2'] {
    animation-name: flat-2;
  }
`;
TdBox.defaultProps = {
  role: 'cell',
};

export const Link = styled.a`
  color: inherit;
  font-size: inherit;
  font-style: inherit;
  font-family: inherit;
  text-decoration: none;
  transition: color 200ms ease;
  // &::after {
  /** Increase touch target size */
  // content: ' ';
  // position: absolute;
  // top: 0;
  // width: 100%;
  // height: 100%;
  // }
  ${th('table.cells.link')}
`;

export const SortButton = styled.buttonBox`
  ${mixins.borderBox}
  ${mixins.button}
  width: 100%;
  text-align: inherit;
  font-weight: inherit;
  display: flex;
  align-items: center;
  transition: color 200ms ease;
  span {
    /** flex:1 */
  }
  svg {
    height: 1rem;
    visibility: hidden;
    transition: width 200ms ease;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  &[data-sorted='true'] svg,
  &:hover svg {
    visibility: visible;
    width: auto;
  }
  ${th('table.cells.sortableHeader')}
`;

export const TableToggleRowsButton: TableToggleRowsButtonProps = styled.buttonBox`
  ${mixins.borderBox}
  ${mixins.button}
  ${mixins.textButton}
  transition:all 200ms ease;
  ${th('table.toggleRowsButton')}
`;

export const TableCellButton = styled.button`
  ${mixins.borderBox}
  ${mixins.button}
`;

export const SymbolWrapper = styled.div`
  position: relative;
`;

export const Ticker = styled.div`
  ${th('text.variants.body2')}
`;

export const Name = styled.div`
  color: text;
  font-size: 0.625rem;
  white-space: nowrap;
  line-height: 0;
  transform: translateY(0.125rem);
  position: absolute;
`;

Name.defaultProps = {
  'data-name': '',
};
