import { createTeleporter } from '@oms/ui-teleporter';
import { Id } from './types';

export const LeftRegion = createTeleporter({
  id: Id.leftAside,
  // role: 'region',
  // region/semantic element should be defined in children
});

export const RightRegion = createTeleporter({
  id: Id.rightAside,
  // role: 'region',
  // region/semantic element should be defined in children
});
