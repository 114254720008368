import React from 'react';
import styled from '@xstyled/emotion';
import { usePortalAttributes } from '@oms/ui-teleporter';

import { useAppShell } from '../AppShellContext';
import { LeftRegion, RightRegion } from '../Portals';
import { ASIDE_WIDTH } from '../constants';

const Aside = styled.box`
  position: fixed;
  width: 100%;
  max-width: ${ASIDE_WIDTH};
  display: flex;
  flex-direction: column;
`;

export interface AppRegionPortalProps {
  children?: React.ReactNode;
  'aria-label'?: string;
  'aria-labelledby'?: string;
  title?: string;
}

export function AppLeftRegionPortal({
  children,
  ...config
}: AppRegionPortalProps) {
  const { top } = useAppShell();
  usePortalAttributes(LeftRegion.ref, config);

  return (
    <>
      <LeftRegion.Source>
        <Aside
          style={{
            left: 0,
            top: `${top?.height}px)`,
            height: `calc(100vh - ${top?.height}px)`,
          }}
        >
          {children}
        </Aside>
        {/** 👇 We use this element to pull of some CSS fun.  */}
        <span data-apply-width />
      </LeftRegion.Source>
    </>
  );
}

export interface AppRightRegionPortalProps extends AppRegionPortalProps {
  isSheet?: boolean;
}

export function AppRightRegionPortal({
  children,
  isSheet,
  ...config
}: AppRightRegionPortalProps) {
  const { top } = useAppShell();
  usePortalAttributes(RightRegion.ref, config);

  if (isSheet) {
    return (
      <>
        <RightRegion.Source>
          {children}
          {/** 👇 We use this element to pull of some CSS fun.  */}
          <span data-apply-width />
        </RightRegion.Source>
      </>
    );
  }
  return (
    <>
      <RightRegion.Source>
        <Aside
          style={{
            right: 0,
            top: `${top?.height}px)`,
            height: `calc(100vh - ${top?.height}px)`,
          }}
        >
          {children}
        </Aside>
        {/** 👇 We use this element to pull of some CSS fun.  */}
        <span data-apply-width />
      </RightRegion.Source>
    </>
  );
}
