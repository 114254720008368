import React, { ReactNode } from 'react';
import { Box } from '@oms/ui-box';
import { Stack } from '@oms/ui-stack';
import {
  Label,
  Error,
  Message,
  ErrorIcon,
  getErrorId,
  getMessageId,
} from '@oms/ui-field';
import { RadioGroupContext } from '@oms/ui-radio';

type SegmentGroup = {
  name: string;
  legend: string;
  children: ReactNode;
  message: ReactNode;
  error: string;
};

export function SegmentGroup({ name, children, legend, message, error }: any) {
  const errorId = getErrorId(name),
    messageId = getMessageId(name);

  return (
    <RadioGroupContext.Provider value={name}>
      <Stack gap={1} as="fieldset" aria-describedby={messageId}>
        <Label as="legend">{legend}</Label>
        <Message id={messageId}>{message}</Message>
        <Box display="flex" flexDirection="row">
          {children}
        </Box>
        <Error id={errorId} data-hidden={!error}>
          <ErrorIcon />
          {error}
        </Error>
      </Stack>
    </RadioGroupContext.Provider>
  );
}
