import React from 'react';
import PropTypes from 'prop-types';
import Jaws from '@oms/jaws';
import { Provider } from './context';

// Will attempt to reconnect for 55 seconds
const MAX_RECONNECT_ATTEMPTS = 10;

type Props = {
  children: React.ReactNode;
  value: {
    instance: typeof Jaws;
  };
};

export class JawsProvider extends React.Component<Props> {
  static displayName = 'JawsProvider';
  static propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.shape({
      instance: PropTypes.instanceOf(Jaws).isRequired,
    }).isRequired,
  };

  reconnectAttempts = 0;
  pingTimestamp: number | null = null;
  pingTimeout: number | null = null;
  pingInterval: number | null = null;
  reconnectTimer: number | null = null;

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: Props) {
    const { instance } = this.props.value;

    if (prevProps.value.instance.useWebSockets !== instance.useWebSockets) {
      this.initialize();
    }
  }

  initialize = () => {
    // NOR-1618 Not using constructor because cDM is only called once.
    // ping/pong handling breaks when bind is called twice
    const { instance: jaws } = this.props.value;

    jaws.onOpen = this.handleOpen;
    jaws.bind('pong', this.handlePong);
    jaws.bind('connection_closed', this.handleConnectionClosed);
  };

  handleOpen = () => {
    const { instance: jaws } = this.props.value;

    this.reconnectAttempts = 0;

    if (jaws.useWebSockets && !this.pingInterval) {
      this.pingInterval = window.setInterval(() => {
        this.pingTimeout = window.setTimeout(() => {
          if (typeof this.pingInterval === 'number') {
            window.clearInterval(this.pingInterval);
          }

          this.pingInterval = null;
          jaws.disconnect();
          this.reconnect(jaws, this.reconnectAttempts++);
        }, 1000);

        this.pingTimestamp = Date.now();
        jaws.ping();
      }, 30000);
    }
  };

  handlePong = () => {
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
    }
  };

  handleConnectionClosed = (event: CloseEvent) => {
    const { instance: jaws } = this.props.value;

    // 4503 happens when the server goes into "busy"-mode. This can happen when
    // too many clients are connected to the server where it will stop accepting
    // new WS connections and instead disconnect with 4503, which tells ut we
    // should switch to REST in order to prevent meltdown.
    //
    // REST is lighter because it is cachable in front of jaws.
    if (event.code === 4503) {
      jaws.useWebSockets = false;
      this.reconnect(jaws, this.reconnectAttempts++);
    }

    // 1001 = Endpoint is "going away". Also (ab)used as user logout in jaws
    // 1006 = Closed abnormally
    if (event.code === 1001 || event.code === 1006) {
      this.reconnect(jaws, this.reconnectAttempts++);
    }
  };

  reconnect = (jaws: typeof Jaws, reconnectAttempts = 0) => {
    let sleep;
    if (reconnectAttempts <= MAX_RECONNECT_ATTEMPTS) {
      sleep = reconnectAttempts * 1000;
    } else {
      sleep = 0;
      jaws.useWebSockets = false; // eslint-disable-line no-param-reassign
    }

    if (typeof this.reconnectTimer === 'number') {
      window.clearTimeout(this.reconnectTimer);
    }

    this.reconnectTimer = window.setTimeout(() => {
      if (jaws.state === 'disconnected') {
        jaws.reconnect();
      }
    }, sleep);
  };

  render() {
    const { value, children } = this.props;

    return <Provider value={value}>{children}</Provider>;
  }
}
