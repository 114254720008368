import React, { useContext } from 'react';
import Jaws from '@oms/jaws';

export const defaultContextValue = {
  // Create a default jaws instance allowing omitting the provider on REST
  instance: new Jaws({
    // Checking location existance for react-native compat
    // In react-native specifying URL in context provider is required
    url:
      (typeof window !== 'undefined' && window.location
        ? window.location.origin
        : '') + '/server/secure',
    useWebSockets: false,
  }),
};

export const context = React.createContext(defaultContextValue);
export const { Consumer, Provider } = context;
export const useJawsContext = () => useContext(context);
