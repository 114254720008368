import formatNumber from './formatNumber';

/**
 * Formats a number into a string with no decimals. Uses
 * a norwegian locale. If you need a more configurable
 * formatter, use `Intl.formatNumber` directly.
 *
 * If the `Intl` API is not supported, the number will not
 * be left untouched and not formatted.
 *
 * @param {number|string} input - The number to be formatted.
 * @param {object} options - Options passed to `Intl.NumberFormat`.
 * @param {string} locale - The locale to use in formatting. Default uses
 * the navigator's language
 * @return {string} The number formatted according to the provided locale.
 */
export default function formatInteger(
  input: number | string,
  options?: Intl.NumberFormat,
  locale: string = navigator.language,
): string {
  return formatNumber(
    input,
    {
      ...options,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    locale,
  );
}
