import { useState } from "react";

/**
 * Simple toggle hook
 * @param {boolean} initialState defaults to false
 */
export function useToggle(initialState: boolean = false) {
  const [open, setOpen] = useState(initialState);

  return {
    isOpen: open,
    close: () => setOpen(false),
    open: () => setOpen(true),
    toggle: () => setOpen(s => !s)
  };
}
