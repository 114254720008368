import React, { forwardRef, ReactNode, Ref } from 'react';
import * as R from 'reakit/Tooltip';
import * as S from './styles';
import mergeRefs from 'react-merge-refs';

export interface TooltipProps {
  children: JSX.Element;
  content: ReactNode;
  placement?: R.TooltipState['placement'];
}

export const Tooltip = forwardRef(function Tooltip(
  { children, content, placement = 'auto' }: TooltipProps,
  ref: Ref<any | null>,
) {
  const tooltip = R.useTooltipState({ placement });
  return (
    <>
      <R.TooltipReference {...tooltip} ref={ref} {...children.props}>
        {(referenceProps) =>
          React.cloneElement(children as JSX.Element, {
            ...referenceProps,
            ref: mergeRefs([ref, referenceProps.ref, children.props.ref]),
          })
        }
      </R.TooltipReference>
      <R.Tooltip {...tooltip} as={S.Tooltip}>
        {content}
      </R.Tooltip>
    </>
  );
});
