import React from 'react';
import {
  forwardRefWithAs,
  splitProps,
  systemProps,
  warning,
  SystemProps,
} from '@oms/ui-utils';
import { useRadioGroup } from './RadioGroup';
import * as S from './styles';

export interface RadioProps extends SystemProps {}

export const Radio = forwardRefWithAs<RadioProps, 'input'>(function Radio(
  { className, ...props },
  ref,
) {
  const [system, rest] = splitProps(props, systemProps as any);
  const name = useRadioGroup();
  warning(
    !props.name || !name,
    '[RadioButton]',
    'you can only use  an unnamed Radio inside a RadioGroup',
  );
  return (
    <S.Container className={`Radio ${className}`} {...system}>
      <S.Input
        ref={ref}
        name={name}
        {...rest}
        type="radio"
        aria-checked={props.checked}
      />
      <S.Indicator role="presentation" data-indicator="" />
    </S.Container>
  );
});
Radio.displayName = 'Radio';
