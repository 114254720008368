import React, { ElementType } from 'react';
import { forwardRefWithAs, SystemProps } from '@oms/ui-utils';
import * as S from './styles';

type TextVariants =
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'subtitle1'
  | 'subtitle2'
  | 'button'
  | 'caption'
  | 'label'
  | 'message'
  | 'error';

export interface TextProps extends SystemProps {
  variant?: TextVariants;
  uppercase?: boolean;
  capitalize?: boolean;
}

export const Text = forwardRefWithAs<TextProps, 'p'>(function Text(
  { as, variant = 'body2', ...props },
  ref
) {
  return (
    <S.Text
      ref={ref}
      as={as}
      display={(as as ElementType) === 'span' ? 'inline' : 'block'}
      variant={variant}
      {...(props as Omit<TextProps, 'color'>)}
    />
  );
});
