import React from 'react';
import styled, { css } from '@xstyled/emotion';
import { th } from '@xstyled/system';
import { mixins } from '@oms/ui-utils';
import { Box, BoxProps } from '@oms/ui-box';
import { Table, TableProps } from '@oms/ui-table';

const animated = ({ animated, ...props }: { animated: boolean }) => {
  const base = th.color('skeleton-base')(props);
  const highlight = th.color('skeleton-highlight')(props);
  if (animated) {
    return css`
      background-image: linear-gradient(90deg, ${base}, ${highlight}, ${base});
    `;
  }
  return css`
    background-image: none;
  `;
};

const SkeletonBox = styled(Box)`
  ${mixins.borderBox}
  ${mixins.borderWidth}
  background-color: skeleton-base;
  ${animated}
  background-size:12rem 100%;
  background-repeat: no-repeat;
  border-radius: sm;
  display: inline-block;
  line-height: 1;
  animation: skeleton 1500ms linear infinite;

  @keyframes skeleton {
    0% {
      background-position: -12rem 0;
    }
    100% {
      background-position: calc(12rem + 100%) 0;
    }
  }
`;

export interface SkeletonProps
  extends BoxProps,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> {
  animated?: boolean;
}

export function Skeleton(props: SkeletonProps) {
  return <SkeletonBox {...props} />;
}

export interface SkeletonTableProps extends Omit<TableProps, 'children'> {
  rows: number;
  columns: number;
  animated?: boolean;
}

export function SkeletonTable({
  rows = 2,
  columns = 1,
  animated,
  ...system
}: SkeletonTableProps) {
  const c = Array(columns).fill('');
  const r = Array(rows).fill('');
  return (
    <Table {...system}>
      <tbody>
        {r.map((_, i1) => (
          <tr key={i1}>
            {c.map((_, i2) => (
              <td key={`${i1}-${i2}`}>
                <Skeleton
                  my="auto"
                  height="100%"
                  width="80%"
                  style={{ transform: 'scaleY(0.8)' }}
                  animated={animated}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
