import { useEffect } from 'react';

export type DocumentTitle = {
  prefix?: string;
  suffix?: string;
  title: string;
  separator?: string;
};

/**
 * A hook to set the document title
 */
export function useDocumentTitle({
  prefix,
  title,
  suffix,
  separator = '-',
}: DocumentTitle) {
  useEffect(() => {
    const p = prefix ? `${prefix} ${separator} ` : '';
    const s = suffix ? ` ${separator} ${suffix}` : '';
    document.title = `${p}${title}${s}`;
  }, [title]);
}
