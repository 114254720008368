import React from 'react';
import { Button } from '@oms/components-buttons';
import { css } from '@emotion/core';
import { Trans } from '@lingui/macro';
import { ErrorBoundaryProps } from '.';

export const errorMessage = css`
  text-align: center;
  width: 100%;
`;

export const resetSection = css`
  margin-top: 8px;
`;

// eslint-disable-next-line react/prop-types
const renderErrorMessage = ({
  error,
  reset,
}: {
  error: any;
  reset?: () => void;
}) => (
  <div css={errorMessage} className="OmsErrorMessage">
    <p>
      <Trans>An error occured</Trans>
    </p>
    <details>
      <p>{error && error.message}</p>
    </details>
    {reset && (
      <div css={resetSection} className="OmsReset">
        <Button onClick={reset}>
          <Trans>Try again</Trans>
        </Button>
      </div>
    )}
  </div>
);

/**
 * The default implementation of `ErrorBoundary` that is used across the
 * components.
 *
 * Calls an `onError` callback when an error is detected, providing a `reset`
 * function to allow the user to retry.
 *
 * @since 1.3.0
 */
export default class DefaultErrorBoundary extends React.Component<
  ErrorBoundaryProps
> {
  static defaultProps = {
    onError: renderErrorMessage,
  };

  state = {
    error: null,
  };

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  reset = () => {
    this.setState({ error: null });
  };

  render() {
    const { children, onError } = this.props;
    const { error } = this.state;

    if (error) {
      return onError({ error, reset: this.reset });
    }

    return children;
  }
}
