import React, { cloneElement, ReactNode, ReactElement } from 'react';
import mergeRefs from 'react-merge-refs';
import { useId, forwardRefWithAs } from '@oms/ui-utils';
import { Text } from '@oms/ui-text';
import { Stack } from '@oms/ui-stack';
import * as R from 'reakit/Popover';
import * as S from './styles';

export interface PopoverProps {
  children: ReactNode;
  disclosure: NonNullable<ReactElement>;
  placement?: 'right-start';
  'aria-label'?: string;
  popoverId?: string;
}

export const Popover = forwardRefWithAs<PopoverProps, 'button'>(
  function PopoverImpl(
    { disclosure, placement, 'aria-label': ariaLabel, children },
    ref,
  ) {
    const popover = R.usePopoverState({ placement, animated: true });
    const id = useId();
    return (
      <>
        <R.PopoverDisclosure
          {...popover}
          ref={ref}
          id={id}
          {...disclosure.props}
        >
          {(disclosureProps) =>
            cloneElement(disclosure, {
              ...disclosureProps,
              ref: mergeRefs([disclosureProps.ref]),
            })
          }
        </R.PopoverDisclosure>
        <S.PopoverPanel
          {...popover}
          aria-labelledby={ariaLabel ? undefined : disclosure.props.id || id}
          aria-label={ariaLabel}
        >
          <S.PopoverArrow {...popover} />
          {typeof children === 'string' ? (
            <Text variant="body2">{children}</Text>
          ) : (
            children
          )}
        </S.PopoverPanel>
      </>
    );
  },
);

export const PopoverActions = S.Actions as typeof Stack;
