import React, { createContext, useContext, ReactNode } from 'react';
import { Stack, StackProps } from '@oms/ui-stack';
import {
  Label,
  Message,
  Error,
  ErrorIcon,
  getErrorId,
  getMessageId,
} from '@oms/ui-field';
import { ReactNodeNoStrings, Size } from '@oms/ui-utils';

export const CheckboxGroupContext = createContext<string>('');
CheckboxGroupContext.displayName = 'CheckboxGroupContext';
export const useCheckboxGroup = (): string => useContext(CheckboxGroupContext);

type CheckboxGroupProps = StackProps & {
  name: string;
  legend?: ReactNode; // ReactNode allows for label={<VisuallyHidden/>}
  children: ReactNodeNoStrings;
  message?: ReactNode;
  error?: string;
  size?: Omit<Size, 'sm'>;
};
export function CheckboxGroup({
  name,
  children,
  legend,
  message,
  error,
  orientation = 'vertical',
  gap = orientation === 'vertical' ? 2 : 3,
}: CheckboxGroupProps) {
  const errorId = getErrorId(name),
    messageId = getMessageId(name);

  return (
    <CheckboxGroupContext.Provider value={name}>
      <Stack gap={1} as="fieldset" aria-describedby={messageId}>
        <Label as="legend">{legend}</Label>
        <Message id={messageId}>{message}</Message>
        <Stack orientation={orientation} gap={gap}>
          {children}
        </Stack>
        <Error id={errorId} data-hidden={!error}>
          <ErrorIcon />
          {error}
        </Error>
      </Stack>
    </CheckboxGroupContext.Provider>
  );
}
CheckboxGroup.displayName = 'CheckboxGroup';
