import React from 'react';
import styled from '@xstyled/emotion';

export interface VisuallyHiddenProps {
  [key: string]: any;
}

export const VisuallyHidden: React.FC<VisuallyHiddenProps> = styled.div`
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  /* remove false positive color contrast errors */
  color: black;
  background-color: white;
`;
VisuallyHidden.defaultProps = {
  'data-hidden': 'true',
  tabIndex: 0,
};
