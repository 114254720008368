import React from 'react';
import { SystemProps } from '@oms/ui-utils';
import * as S from './styles';

export function Separator({
  orientation: o1 = 'horizontal',
  'aria-orientation': o2,
  ...props
}: SeparatorProps) {
  const orientation = o2 || o1;
  return (
    <S.Separator
      as={orientation === 'horizontal' ? 'hr' : 'div'}
      role="separator"
      aria-orientation={orientation}
      {...props}
      width={orientation === 'vertical' ? 0 : null}
      mx={0}
      my={0}
    />
  );
}

export interface SeparatorProps extends SystemProps {
  orientation?: 'horizontal' | 'vertical';
  /** @private */
  'aria-orientation'?: 'horizontal' | 'vertical';
}
