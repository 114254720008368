import React from 'react';
import styled, { css } from '@xstyled/emotion';
import { breakpoints } from '@xstyled/system';
import { SystemProps } from '@oms/ui-utils';
import { Box } from '@oms/ui-box';

export const HeaderContainer = styled.headerBox`
  background-color: surface-1;
  box-shadow: sm;
  border-bottom: sm;
  border-bottom-color: border;
  display: grid;
  height: 3.75rem;
  align-items: center;
  ${breakpoints({
    base: css`
      grid-template-columns: auto 1fr auto;
      grid-template-areas: 'logo content drawer';
    `,
    md: css`
      grid-template-columns: auto auto 1fr;
      grid-template-areas: 'drawer logo content';
    `,
  })}
`;

export interface AppNavigationBarProps extends SystemProps {
  logo?: React.ReactElement;
  navigation?: React.ReactElement;
  children?: React.ReactNode;
}

/**
 * Layout
 *  base: [[-Logo-] children [-Menu-]]
 *  md:   [[-Menu-][-Logo-] children ]
 */

export const AppNavigationBar = ({
  logo,
  navigation,
  children,
  ...system
}: AppNavigationBarProps) => {
  return (
    <HeaderContainer {...system}>
      {navigation}
      {logo}
      <Box
        display="flex"
        alignItems="center"
        gridArea="content"
        height="100%"
        px={3}
      >
        {children}
      </Box>
    </HeaderContainer>
  );
};
