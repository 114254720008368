import React, { createContext, useContext, ReactNode } from 'react';
import { forwardRefWithAs, SystemProps } from '@oms/ui-utils';
import { useField } from '@oms/ui-components-context';
import { RenderField } from './RenderField';
export interface FieldsetProps {
  name: string;
  legend: string;
  message?: ReactNode;
  as: any;
  children: ReactNode;
  validate?: (value: any) => string | undefined | void;
}

const GroupContext = createContext('');
GroupContext.displayName = 'FieldsetContext';
/**
 * A group component that registers a fieldset with the containing form, subscribes to field state,
 * and injects both field state.
 */
export const Fieldset = forwardRefWithAs<FieldsetProps, 'fieldset'>(
  function ConnectedGroup(
    { name, legend, children, as: Component, message, validate, ...props },
    ref,
  ) {
    const { meta } = useField(name, { validate });
    const error = meta.touched && meta.error;
    return (
      <GroupContext.Provider value={name}>
        <Component
          ref={ref}
          name={name}
          legend={legend}
          message={message}
          error={error}
          {...props}
        >
          {children}
        </Component>
      </GroupContext.Provider>
    );
  },
);

/**
 * A component that registers a field with the containing form, subscribes to field state,
 * and injects both field state and callback functions, onBlur, onChange, and onFocus.
 */
export interface FieldProps extends SystemProps {
  /** Component */
  as: any;
  /** The name of your field. Field values may be deeply nested using dot-and-bracket syntax. */
  name?: string;
  label: string | React.ReactElement;
  /** Using the placement option only affects text input like components  */
  message?:
    | string
    | JSX.Element
    | { placement: 'top' | 'bottom'; text: string | JSX.Element };
  type?: 'checkbox' | 'radio' | undefined;
  children?: ReactNode;
  validate?: (value: any) => string | undefined | void;
}

/** Returns the input type based on the component's display name  */
export const getType = (type: string): 'checkbox' | 'radio' | undefined => {
  switch (type) {
    case 'Segment':
    case 'Radio':
    case 'Choice':
      return 'radio';
    case 'Switch':
    case 'Checkbox':
      return 'checkbox';
    default:
      return;
  }
};

export const Field = forwardRefWithAs<FieldProps, 'input'>(function Field(
  { as, name, type = getType(as.displayName), children, validate, ...rest },
  ref,
) {
  const groupName = useContext(GroupContext);
  const { input, meta } = useField(name || groupName, {
    type,
    validate,
    ...rest,
  });
  const error = meta.touched && meta.error;
  return (
    <RenderField
      ref={ref}
      {...rest}
      {...input}
      as={as}
      error={error}
      content={children}
    />
  );
});
