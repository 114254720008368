import React, { useRef } from 'react';
import {
  forwardRefWithAs,
  useForkRef,
  SystemProps,
  mixins,
} from '@oms/ui-utils';
import styled from '@xstyled/emotion';
import { th } from '@xstyled/system';

/**
 * Uses the color provided as a prop, else it resolves the color specified in the theme.
 * @param override
 * @param themeGetter
 */
const pick = (
  override: string,
  themeGetter: (token: string) => (props: any) => any,
) => (props: any) => {
  return th.color(props[override])(props) || themeGetter(props);
};

// offset in pixels
const offset = 32;
const useScrollFade = (
  ref: React.MutableRefObject<HTMLElement | null | undefined>,
  orientation: 'vertical' | 'horizontal' | null = 'vertical',
) => {
  const [state, setState] = React.useState<Partial<Record<string, boolean>>>({
    showStartFade: false,
    showEndFade: false,
  });

  React.useEffect(() => {
    const box = ref.current;
    const handler = (_event?: Event) => {
      if (box) {
        const {
          scrollHeight,
          scrollTop,
          offsetHeight,
          scrollWidth,
          scrollLeft,
          offsetWidth,
        } = box;
        const showStartFade =
          orientation === 'vertical' ? scrollTop > offset : scrollLeft > offset;
        const showEndFade =
          orientation === 'vertical'
            ? scrollHeight - scrollTop - offsetHeight > offset
            : scrollWidth - scrollLeft - offsetWidth > offset;
        setState({ showStartFade, showEndFade });
      }
    };
    box?.addEventListener('scroll', handler);
    /** runs once on mount */
    handler();
    return () => box?.removeEventListener('scroll', handler);
  }, []);

  return state;
};

const ScrollBox = styled.box`
  ${mixins.borderBox}
  ${mixins.borderWidth}
  > * {
    min-height: 0;
  }
  position: relative;
  &[data-orientation='vertical'] {
    overflow-y: auto;
    overflow-x: hidden;
  }
  &[data-orientation='horizontal'] {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
  }
  &[data-orientation='both'] {
    overflow-y: auto;
    overflow-x: auto;
  }

  @supports not (-ms-high-contrast: none) {
    /* Non-IE styles here */
    /* Vertical scrolling */

    &[data-orientation='vertical'][data-fade-end]::after,
    &[data-orientation='vertical'][data-fade-start]::before {
      content: '';
      display: block;
      width: 100%;
      height: 32px;
      left: 0px;
      pointer-events: none;
      z-index: 1;
      transition: transform 100ms ease;
    }

    &[data-orientation='vertical'][data-fade-start]::before {
      margin-top: -32px;
      position: sticky;
      top: 0px;
      background: linear-gradient(
        0deg,
        ${pick('fadeOutColor', th('colors.scroll-fade-out'))} 0%,
        ${pick('fadeInColor', th('colors.scroll-fade-in'))} 100%
      );
      transform: scaleY(0);
      transform-origin: top;
    }

    &[data-orientation='vertical'][data-fade-end]::after {
      margin-bottom: -32px;
      position: sticky;
      bottom: 0px;
      background: linear-gradient(
        0deg,
        ${pick('fadeInColor', th('colors.scroll-fade-in'))} 0%,
        ${pick('fadeOutColor', th('colors.scroll-fade-out'))} 100%
      );
      transform: scaleY(0);
      transform-origin: bottom;
    }

    /* Horizontal scrolling */

    &[data-orientation='horizontal'][data-fade-end]::after,
    &[data-orientation='horizontal'][data-fade-start]::before {
      content: '';
      display: block;
      width: 32px;
      min-width: 32px;
      top: 0px;
      pointer-events: none;
      z-index: 1;
      transition: transform 100ms ease;
    }

    &[data-orientation='horizontal'][data-fade-start]::before {
      margin-left: -32px;
      position: sticky;
      left: 0px;
      background: linear-gradient(
        -90deg,
        ${pick('fadeOutColor', th('colors.scroll-fade-out'))} 0%,
        ${pick('fadeInColor', th('colors.scroll-fade-in'))} 100%
      );
      transform: scaleX(0);
      transform-origin: left;
    }

    &[data-orientation='horizontal'][data-fade-end]::after {
      margin-right: -32px;
      position: sticky;
      right: 0px;
      background: linear-gradient(
        270deg,
        ${pick('fadeInColor', th('colors.scroll-fade-in'))} 0%,
        ${pick('fadeOutColor', th('colors.scroll-fade-out'))} 100%
      );
      transform: scaleX(0);
      transform-origin: right;
    }

    &[data-orientation='horizontal'][data-fade-start='true']::before,
    &[data-orientation='horizontal'][data-fade-end='true']::after,
    &[data-orientation='vertical'][data-fade-start='true']::before,
    &[data-orientation='vertical'][data-fade-end='true']::after {
      transform: scaleX(1);
    }
  }

  /**  
  * Blink- and WebKit-based browsers (e.g., Chrome, Edge, Opera, Safari, all browsers on iOS, and others) 
  * @see https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar
  */
  &::-webkit-scrollbar {
    width: 0.675rem;
    height: 0.675rem;
    background-color: ${pick('trackIdleColor', th('colors.scroll-track-idle'))};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${pick('thumbIdleColor', th('colors.scroll-thumb-idle'))};
    border-radius: 999px;
  }

  :hover {
    &::-webkit-scrollbar {
      background-color: ${pick('trackColor', th('colors.scroll-track'))};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${pick('thumbColor', th('colors.scroll-thumb'))};
    }
  }

  /** 
  * Gecko-based browsers (e.g. Firefox)
  * @see https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar
  */
  scrollbar-color: ${pick('thumbIdleColor', th('colors.scroll-thumb-idle'))}
    ${pick('trackIdleColor', th('colors.scroll-track-idle'))};

  :hover {
    scrollbar-color: ${pick('thumbColor', th('colors.scroll-thumb'))}
      ${pick('trackColor', th('colors.scroll-track'))};
  }
`;

export interface ScrollProps extends SystemProps {
  children: React.ReactNode;
  /** Scroll axis */
  orientation?: 'horizontal' | 'vertical' | null;
  thumbColor?: string;
  thumbIdleColor?: string;
  trackColor?: string;
  trackIdleColor?: string;
  fadeInColor?: string;
  fadeOutColor?: string;
  showFade?: boolean;
}

/**
 * Scrollbar 'idle' styles are only available in Blink- and WebKit-based browsers (e.g., Chrome, Edge, Opera, Safari, all browsers on iOS, and others).
 * Default styles are available Gecko (Firefox) and on Blink- and WebKit-based browsers
 */
export const Scroll = forwardRefWithAs<ScrollProps, 'div'>(function Scroll2(
  { children, orientation = 'vertical', showFade = true, ...props },
  ref,
) {
  const innerRef = useRef<HTMLElement | null>();
  const forkedRef = useForkRef(ref, innerRef);
  const { showStartFade, showEndFade } = useScrollFade(innerRef, orientation);

  return (
    <ScrollBox
      ref={forkedRef}
      data-fade-start={showFade && showStartFade}
      data-fade-end={showFade && showEndFade}
      data-orientation={orientation || 'both'}
      {...props}
    >
      {children}
    </ScrollBox>
  );
});
