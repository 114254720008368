import React from 'react';
import { forwardRefWithAs } from '@oms/ui-utils';
import { TextInput, TextInputProps } from '@oms/ui-text-input';

type TextInputPropsWithoutType = Omit<TextInputProps, 'type'>;

export interface NumberInputProps extends TextInputPropsWithoutType {
  /**
   * default `en-150` which supports both comma's and periods as a decimal separator
   * @see https://www.ctrl.blog/entry/html5-input-number-localization.html */
  lang?: string;
  inputMode?: 'decimal' | 'numeric';
  step?: number;
}

export const NumberInput = forwardRefWithAs<NumberInputProps, 'input'>(
  function NumberInput(
    {
      inputMode = 'numeric',
      step = inputMode === 'numeric' ? 1 : 0.0001,
      lang = 'en-150',
      as: _as,
      type: _type,
      ...props
    },
    ref,
  ) {
    return (
      <TextInput
        ref={ref}
        {...props}
        type="number"
        step={step}
        lang={lang}
        inputMode={inputMode}
        autoComplete="off"
      />
    );
  },
);
NumberInput.displayName = 'NumberInput';
