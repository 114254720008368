import React, {
  createContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import isEqual from 'react-fast-compare';
import { useDebouncedCallback } from 'use-debounce';
import { useResizeObserver } from '@oms/ui-element-query';
import { Id } from './types';

// ReturnType of useResizeObserver [React.Ref<any>, ResizeObserverEntry]
type Keys = keyof typeof Id;
type Rects = Partial<Record<Keys, DOMRect | undefined>>;

const getBoundingClientRect = (element: HTMLElement | null) => {
  if (element) {
    const {
      top,
      right,
      bottom,
      left,
      width,
      height,
      x,
      y,
    } = element.getBoundingClientRect();
    return { top, right, bottom, left, width, height, x, y };
  }
  return;
};

const AppShellContext = createContext<Rects>({
  [Id.contentArea]: undefined,
  [Id.rightAside]: undefined,
  [Id.leftAside]: undefined,
  [Id.top]: undefined,
});
const AppShellObserverContext = createContext<
  Record<string, React.Ref<any> | null>
>({});

// prettier-ignore
const useIsoMorphicLayoutEffect = typeof document !== 'undefined' ? useLayoutEffect : useEffect;

export const useAppShell = () => React.useContext(AppShellContext);
// prettier-ignore
export const useAppShellObserver = () => React.useContext(AppShellObserverContext);

export function AppShellProvider({ children }: any) {
  const [appRef, appSize] = useResizeObserver();
  const [topRef, topSize] = useResizeObserver();
  const [domRect, setDomRect] = React.useState<Rects>({});
  const [debouncedCallback] = useDebouncedCallback((value: Rects) => {
    setDomRect(value);
  }, 300);
  const observerState = useMemo(
    () => ({
      appRef,
      topRef,
    }),
    [appRef, topRef],
  );
  useIsoMorphicLayoutEffect(() => {
    const top = document.getElementById(Id.top);
    const contentArea = document.getElementById(Id.contentArea);
    const rightAside = document.getElementById(Id.rightAside);
    const leftAside = document.getElementById(Id.leftAside);

    const newRect = {
      [Id.top]: getBoundingClientRect(top),
      [Id.contentArea]: getBoundingClientRect(contentArea),
      [Id.rightAside]: getBoundingClientRect(rightAside),
      [Id.leftAside]: getBoundingClientRect(leftAside),
    };
    if (!isEqual(newRect, domRect)) {
      debouncedCallback(newRect);
    }
  }, [appSize, topSize]);

  return (
    <AppShellContext.Provider value={domRect}>
      <AppShellObserverContext.Provider value={observerState}>
        {children}
      </AppShellObserverContext.Provider>
    </AppShellContext.Provider>
  );
}
