import styled from '@xstyled/emotion';
import { light } from '@oms/ui-icon';
import { IconButton } from '@oms/ui-icon-button';

/**
 * CloseButton, only for use within this package.
 * @private
 */
const CloseButton = styled(IconButton)`
  transform: translateY(-0.125rem);
  svg {
    fill: inverse-text;
  }
  &:focus,
  &:hover {
    svg {
      fill: primary;
    }
  }
`;

CloseButton.defaultProps = {
  icon: light.faTimes,
  'aria-label': 'Close menu',
};

export { CloseButton };
