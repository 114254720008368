import React from 'react';
import { Global, css, useTheme } from '@xstyled/emotion';

export const BoxSizingStyles = () => (
  <Global
    styles={css`
      * {
        &,
        &::before,
        &::after {
          box-sizing: border-box;
        }
      }
    `}
  />
);

export interface GlobalProps {
  backgroundColor?: string;
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  focusBoxShadow?: string;
  rootId?: string;
}

/**
 * Sets a global text color, font size and applies the focus shadow style to focusable elements.
 */
export const GlobalStyles = ({
  backgroundColor,
  color,
  fontFamily,
  fontSize,
  // focusBoxShadow,
  rootId = 'root',
}: any) => {
  const theme = useTheme();
  const bg = backgroundColor || theme.colors['surface-1'];
  const fc = color || theme.colors['text'];
  const ff = fontFamily || theme.fonts['body'];
  const fs = fontSize || theme.fontSizes['md'];
  // const bs = focusBoxShadow || theme.shadows['focus'];
  return (
    <Global
      styles={css`
        /**
        * 1. Prevent padding and border from affecting element width
        * https://goo.gl/pYtbK7
        * 2. Correct the line height in all browsers.
        * 3. Prevent adjustments of font size after orientation changes in iOS.
        */
        html {
          box-sizing: border-box; /* 1 */
          line-height: 1.15; /* 2 */
          -webkit-text-size-adjust: 100%; /* 3 */
          -webkit-tap-highlight-color: rgb(0, 0, 0, 0);
          -webkit-overflow-scrolling: touch;
          background-color: ${bg};
        }
        *,
        *::before,
        *::after {
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          -webkit-text-size-adjust: 100%;
          text-rendering: optimizelegibility;
        }
        body {
          padding: 0 !important;
        }
        #${rootId} {
          min-height: 100vh;
          width: 100%;
          padding: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;
        }

        /**
        * Remove the margin in all browsers.
        */
        body {
          font-family: ${ff};
          color: ${fc};
          font-size: ${fs};
          *:focus {
            outline-color: transparent;
          }
        }

        /**
        * Render the main element consistently in IE.
        */
        main {
          display: block;
        }
        /**
  * Add the correct display in IE 10.
  */
        [hidden] {
          display: none;
        }
      `}
    />
  );
};
