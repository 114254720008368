import React, { forwardRef } from 'react';
import {
  defaultItemToString,
  RenderItemProps as SelectRenderItemProps,
} from '@oms/ui-select';
import { Text } from '@oms/ui-text';
import { Icon, light } from '@oms/ui-icon';
import { HighlightedText as Highlighter } from '@oms/ui-highlighted-text';

export interface RenderItemProps extends SelectRenderItemProps {
  inputValue?: string;
  HighlightedText?: typeof Highlighter;
}

export const RenderItem = forwardRef(function RenderItem(
  {
    item,
    inputValue,
    isHighlighted,
    isSelected,
    itemProps,
    itemToString = defaultItemToString,
    ItemContainer,
    HighlightedText = Highlighter,
    ...props
  }: RenderItemProps,
  ref: React.Ref<any>,
) {
  if (!itemToString) {
    return null;
  }
  return (
    <ItemContainer
      ref={ref}
      {...props}
      data-highlighted={isHighlighted}
      data-selected={isSelected}
    >
      <Text size="md-mobile" color="inherit">
        <HighlightedText search={inputValue || ''} autoEscape={true}>
          {itemToString(item)}
        </HighlightedText>
      </Text>
      {isSelected && <Icon ml="auto" icon={light.faCheck} />}
    </ItemContainer>
  );
});
