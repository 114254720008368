import DefaultErrorBoundary from './DefaultErrorBoundary';
import { ContextTypes } from '.';

const host = `//${process.env.JAWS_URL || window.location.host}`;
const baseUrl = `${host}/server`;

export default {
  baseUrl,
  domainUrl: '{+baseUrl}/domain/{?query*}',
  graphdataUrl:
    '{+baseUrl}/components/graphdata/{series}/{space}/{itemSector}{?query*}',
  transactionsUrl:
    '{+baseUrl}/secure/transactions/{backend}/{userId}/{portfolioId}{/instrumentId,transactionId}{?instrumentId,fromDate,settledStatus,query*}',
  orderUrl:
    '{+baseUrl}/secure/customers/{userId}/{type}-orders/{orderId}{?accountId}',
  orderContractNotesUrl:
    '{+baseUrl}/secure/customers/{userId}/{type}-orders/{orderId}/contract-notes{?accountId}',
  orderTransactionUrl:
    '{+baseUrl}/secure/customers/{userId}/{type}-orders/{orderId}/transactions/{transactionId}{?accountId}',
  fundTransactionUrl:
    '{+baseUrl}/secure/customers/{userId}/fund-transactions/{transactionId}{?accountId}',
  orderSearchUrl:
    '{+baseUrl}/secure/customers/{userId}/{type}-orders/search{?accountId,filters*}',
  transactionSearchUrl:
    '{+baseUrl}/secure/customers/{userId}/{type}-transactions/search{?accountId,filters*}',
  jawsUrl: '{+baseUrl}/components{?spec*}',
  ErrorBoundary: DefaultErrorBoundary,
  Suggest: (props) => {
    console.warn(
      'Consuming Suggest from context has been deprecated. Please use useSuggest from @oms/components-suggest instead',
    );
    return null;
  },
} as ContextTypes;
