import React from 'react';

export const Button = (props: React.HTMLProps<HTMLButtonElement>) => {
  const { children, className = 'OmsButton', type = 'button' } = props;

  return (
    <button
      {...props}
      className={className}
      type={type as JSX.IntrinsicElements['button']['type']}
    >
      {children}
    </button>
  );
};

export default Button;
